<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton Start -->
    <!-- <v-col v-show="loading == true">
      <v-sheet
        height="565"
        class="pa-2 mb-n5"
        max-width="6000"
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
      >
        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="400"
              type="heading"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
            
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
            ></v-skeleton-loader> 
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
              class="ml-2"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
        
        <div>
          <v-skeleton-loader
            tile
            height="50"
            type="image"
            class="mt-1"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              type="image"
              width="250"
              height="20"
              class="rounded-xl mx-n1 mt-1 mb-1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>

        <div>
          <v-skeleton-loader
            tile
            height="200"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            tile
            height="132"
            type="image"
            class="mb-3"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="800"
              type="heading"
              class="mt-1 mx-n2"
            ></v-skeleton-loader>

            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="image"
              width="170"
              height="20"
              class="rounded-xl mx-n1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-col> -->
    <!-- loading skeleton End -->
   
    <!-- Alert Tidak Punya Akses Halaman -->
    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu Tidak punya Akses Ke halaman "Karyawan"
      </v-alert>
    </v-col>

    <!-- Alert Data Tersimpan -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      v-model="Alert"
      class="rounded-lg"
      icon="mdi-check-bold"
      transition="slide-x-transition"
    >
      <v-icon class="mr-1">check-circle</v-icon>
      Data Berhasil Disimpan
    </v-alert>

    <!-- Alert Data Berhasil Terubah -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      class="rounded-lg"
      icon="mdi-check-bold"
      v-model="AlertBerhasilTerubah"
      transition="slide-x-transition"
    >
      Data Berhasil Diubah
    </v-alert>

    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-delete"
      v-model="AlertDataTerhapus"
      transition="slide-x-transition"
    >
      Data Berhasil Dihapus
    </v-alert>

    <!-- ---------------------------------------------------- -->

    <v-container fluid v-show="this.valid == true">
      <v-sheet tile outlined class="elevation-3 pa-2">
        <v-toolbar
          flat
          fixed
          outlined
          height="55"
          color="dark"
          v-resize="onResize"
        >
          <v-toolbar-title>
            Data Karyawan
          </v-toolbar-title>
          <v-divider inset vertical class="ml-2"></v-divider>
          <v-spacer></v-spacer>

          <v-card-actions class="mr-n4">
            <!-- refresh mode biasa  -->
            <v-btn
              @click="refreshdata"
              v-show="mobile == false"
              class="subtitle-1 text-capitalize ma-2"
              outlined
              text
              :disabled="loading == true"
            >
            <v-icon class="mr-1">mdi-refresh</v-icon>
              <span>Refresh Data</span>
            </v-btn>
            <!-- refresh mode mobile -->
            <v-btn
              v-show="mobile == true"
              @click="refreshdata"
              class="subtitle-1 text-capitalize ma-2"
              outlined
              small
              text
              fab
              :disabled="loading == true"
            >
            <v-icon class="ml-1">mdi-refresh</v-icon>
            </v-btn>
            <!-- Menu Export Data -->
            <div class="text-center">
              <v-menu
                v-model="MenuExport"
                :close-on-content-click="false"
                :nudge-width="1"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <!-- Btn Export Mode Biasa -->
                  <v-btn
                    @click="MenuExport = !MenuExport"
                    class="subtitle-1 text-capitalize"
                    v-show="mobile == false"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    :disabled="loading == true"
                  >
                    <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                    <span>Export Data</span>
                    <v-icon class="ml-1 mr-n2">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </template>

                <v-card outlined v-show="MenuExport">
                  <!-- PDF -->
                  <div class="ma-2">
                    <v-btn
                      text
                      block
                      outlined
                      @click.native="PdfExport"
                      class="text-capitalize subtitle-1"
                    >
                      Pdf
                      <v-icon right>mdi-file-document-outline</v-icon>
                    </v-btn>
                  </div>

                  <!-- EXCEL -->
                  <div class="ma-2">
                    <v-btn
                      text
                      block
                      outlined                       
                      @click.native="excelExport"
                      class="text-capitalize subtitle-1"
                    >
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-menu>

              <!-- Btn Export Dialog Mode Mobile -->
              <v-dialog
                v-model="DialogPilihExport"
                max-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="mobile == true"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    text
                    fab
                    :disabled="loading == true"
                  >
                    <v-icon>mdi-table-arrow-right</v-icon>
                  </v-btn>
                </template>
                <v-card class="rounded-lg">
                  <v-toolbar dense>
                    <v-layout justify-center>
                      <v-toolbar-title>
                        Pilih Export File
                      </v-toolbar-title>
                    </v-layout>
                  </v-toolbar>
                  <v-card class="pa-2">
                    <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                      Pdf
                      <v-icon right>mdi-file-document-outline</v-icon>
                    </v-btn>

                    <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </v-card>
                </v-card>
              </v-dialog>
            </div>

            <!-- Dialog Tambah Karyawan -->
            <v-dialog
              v-model="DialogTambahKaryawan"
              max-width="700px"
              scrollable
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Tambah Mode Desktop -->
                <v-btn
                  class="subtitle-1 text-capitalize"
                  v-show="mobile == false && ValidCreate == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="mr-1">mdi-plus-thick</v-icon>
                  <span class="mr-1">Tambah</span>
                </v-btn>

                <!-- Btn Tambah Mode Mobile -->
                <v-btn 
                  class="mr-n2"
                  v-show="mobile == true && ValidCreate == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <v-card outlined class="rounded-lg">
                <v-toolbar flat height="68">
                  <v-toolbar-title>
                    <div class="text-truncate"><span class="text-h5">{{ formTitleKaryawan }}</span></div>
                    <div class="mt-n1 caption text-truncate"><span v-show="showData == false" v-text="TampilNama"></span></div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- Button Menu Tampilan Buka Semua Inputan Atau Tutup -->
                  <v-menu :close-on-content-click="closeOnContentClick">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="rounded-lg pa-2">
                      <v-list class="mt-n2 mb-n2">
                        <v-btn text block small outlined rounded class="text-capitalize" @click="ShowAll">Tampilkan Semua</v-btn>
                      </v-list>
                      <v-list class="mb-n2">
                        <v-btn text block small outlined rounded class="text-capitalize" @click="CloseAll">Tutup Semua</v-btn>
                      </v-list>                      
                    </v-card>
                  </v-menu>

                  <v-btn
                    icon
                    @click="KeluarDialogKaryawan"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                
                <v-card-text>
                  <div class="mr-n3 ml-n3 mt-3 mb-n2">
                    <v-sheet outlined class="pa-2">
                      <!-- Data -->
                      <div>
                        <v-sheet outlined>
                          <a>
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <v-sheet
                                @click="showData = !showData"
                                :dark="hover ? true : false"
                                :color="hover ? 'grey darken-2' : '$vuetify.theme.dark'"
                                :class="hover == { 'on-hover': hover } == $vuetify.theme.dark ? 'grey lighten-4' : '$vuetify.theme.dark'"
                              >
                                <v-card-actions>
                                  <v-icon left>mdi-account-details</v-icon>
                                  <span class="subtitle-1 font-weight-medium">Data</span>
                                  <v-spacer></v-spacer>
                                  <v-btn text small outlined class="text-capitalize">{{ showData ? 'Tutup' : 'Tampilkan' }}</v-btn>
                                </v-card-actions>
                              </v-sheet>
                            </v-hover>
                          </a>

                          <v-expand-transition transition="false">
                            <div v-show="showData">
                              <v-divider></v-divider>
                              <div class="pa-5">
                                <v-row>
                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Nama"
                                      color="dark"
                                      v-model="editedItem.Nama"
                                      hint="Wajib Diisi." 
                                      :rules="[value => !!value || 'Tidak Boleh Kosong']"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      :disabled="editedIndex == -1 ? true : false "
                                      dense
                                      label="NRK"
                                      color="dark"
                                      v-model="editedItem.NRK"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="POH"
                                      color="dark"
                                      v-model="editedItem.POH"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Lokasi"
                                      color="dark"
                                      v-model="editedItem.Lokasi"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-autocomplete
                                      dense
                                      label="Cabang"
                                      color="dark"
                                      :items="DataCabang"
                                      item-text="NamaCabang"
                                      item-value="KodeCabang"
                                      v-model="editedItem.Cabang"
                                    ></v-autocomplete>
                                  </v-col>
                                  
                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-autocomplete
                                      dense
                                      label="Kategori Absen"
                                      color="dark"
                                      :items="DataKategoriAbsen"
                                      item-text="Kategori"
                                      item-value="id"
                                      v-model="editedItem.KategoriAbsen"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-dialog
                                      v-model="MenuTglMasuk"
                                      :return-value.sync="date"
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          v-model="editedItem.TglMasuk"
                                          label="Tgl Masuk"
                                          prepend-icon="mdi-calendar"
                                          color="dark"
                                          readonly
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="editedItem.TglMasuk"
                                        @input="MenuTglMasuk = false"
                                        year-icon="mdi-calendar-blank"
                                        color="red darken-4"
                                        class="rounded-lg"
                                        locale="id"
                                        scrollable
                                      ></v-date-picker>
                                    </v-dialog>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6" v-show="formTitleKaryawan == 'Ubah Data Karyawan'">
                                    <v-text-field
                                      dense
                                      readonly
                                      label="Masa Kerja"
                                      color="dark"
                                      v-model="MasaKerja"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Sisa Cuti"
                                      color="dark"
                                      v-model="editedItem.Cuti"
                                    ></v-text-field>
                                  </v-col>
                                  
                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Tempat Lahir"
                                      color="dark"
                                      v-model="editedItem.TempatLahir"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-dialog
                                      v-model="MenuTglLahir"
                                      :return-value.sync="date"
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          v-model="editedItem.TglLahir"
                                          label="Tgl Lahir"
                                          prepend-icon="mdi-calendar"
                                          color="dark"
                                          readonly
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="editedItem.TglLahir"
                                        @input="MenuTglLahir = false"
                                        year-icon="mdi-calendar-blank"
                                        color="red darken-4"
                                        class="rounded-lg"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                        locale="id"
                                        ref="picker"
                                      ></v-date-picker>
                                    </v-dialog>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="12" lg="12">
                                    <v-text-field
                                      dense
                                      label="Alamat KTP"
                                      color="dark"
                                      v-model="editedItem.AlamatKTP"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="12" lg="12">
                                    <v-text-field
                                      dense
                                      label="Alamat Sekarang"
                                      color="dark"
                                      v-model="editedItem.AlamatSekarang"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="No. Tlp"
                                      color="dark"
                                      v-model="editedItem.NoTlp"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="No. Tlp Emergency"
                                      color="dark"
                                      v-model="editedItem.NoTlpEmergency"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-autocomplete
                                      dense
                                      v-model="editedItem.JenisKelamin"
                                      :items="['Laki-Laki','Perempuan']"
                                      color="dark"
                                      label="Jenis Kelamin"
                                    ></v-autocomplete>
                                    <!-- <v-text-field
                                      dense
                                      label="Jenis Kelamin"
                                      color="dark"
                                      v-model="editedItem.JenisKelamin"
                                    ></v-text-field> -->
                                    <!-- <v-combobox
                                      dense
                                      :items="['Laki-Laki','Perempuan']"
                                      label="Jenis Kelamin"
                                      color="dark"
                                      v-model="editedItem.JenisKelamin"
                                    ></v-combobox> -->
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Pendidikan Terakhir"
                                      color="dark"
                                      v-model="editedItem.PendidikanTerakhir"
                                    ></v-text-field>
                                    <!-- <v-combobox
                                      dense
                                      label="Pendidikan Terakhir"
                                      color="dark"
                                      :items="['SD/Sederajat','SLTP/Sederajat','SLTA/Sederjat','Diploma I/I','Akademi/Diploma III','Strata I','Strata II','Strata III']"
                                      v-model="editedItem.Pendidikan_Terakhir"
                                    ></v-combobox> -->
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Jurusan"
                                      color="dark"
                                      v-model="editedItem.Jurusan"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-combobox
                                      dense
                                      :items="['Islam','Protestan','Katolik','Hindu','Buddha','Khonghucu']"
                                      label="Agama"
                                      color="dark"
                                      v-model="editedItem.Agama"
                                    ></v-combobox>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Suku"
                                      color="dark"
                                      v-model="editedItem.Suku"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="No. Rekening Tabungan"
                                      color="dark"
                                      v-model="editedItem.NoRekTabungan"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="No. Kartu BPJS TK"
                                      color="dark"
                                      v-model="editedItem.NoKartuBPJSTK"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="No. Kartu BPJS KES"
                                      color="dark"
                                      v-model="editedItem.NoKartuBPJSKes"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="No. KTP"
                                      color="dark"
                                      v-model="editedItem.NoKTP"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="No. NPWP"
                                      color="dark"
                                      v-model="editedItem.NoNPWP"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-combobox
                                      dense
                                      label="Golongan Darah"
                                      color="dark"
                                      :items="['A','B','AB','O']"
                                      v-model="editedItem.GolDarah"
                                    ></v-combobox>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Status Perkawinan"
                                      color="dark"
                                      v-model="editedItem.StatusPerkawinan"
                                    ></v-text-field>
                                    <!-- <v-combobox
                                      dense
                                      label="Status"
                                      color="dark"
                                      :items="['Belum Menikah','Menikah']"
                                      v-model="editedItem.StatusPerkawinan"
                                    ></v-combobox> -->
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Status Kerja"
                                      color="dark"
                                      v-model="editedItem.StatusKerja"
                                    ></v-text-field>
                                    <!-- <v-combobox
                                      dense
                                      label="Status Kerja"
                                      color="dark"
                                      :items="['Aktif','Tidak Aktif']"
                                      v-model="editedItem.StatusKerja"
                                    ></v-combobox> -->
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Ukuran Baju"
                                      color="dark"
                                      v-model="editedItem.UkuranBaju"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Ukuran Sepatu"
                                      color="dark"
                                      v-model="editedItem.UkuranSepatu"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="PHK"
                                      color="dark"
                                      v-model="editedItem.PHK"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-dialog
                                      v-model="MenuTglPHK"
                                      :return-value.sync="date"
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          v-model="editedItem.TglPHK"
                                          label="Tanggal PHK"
                                          prepend-icon="mdi-calendar"
                                          color="dark"
                                          readonly
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="editedItem.TglPHK"
                                        @input="MenuTglPHK = false"
                                        year-icon="mdi-calendar-blank"
                                        color="red darken-4"
                                        class="rounded-lg"
                                        locale="id"
                                      ></v-date-picker>
                                    </v-dialog>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Keterangan"
                                      color="dark"
                                      v-model="editedItem.Keterangan"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      label="Nama Istri Suami"
                                      color="dark"
                                      v-model="editedItem.NamaIstriSuami"
                                    >
                                    </v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      prefix="Anak"
                                      color="dark"
                                      readonly
                                      v-model="DataAnak.length"
                                    >
                                      <!-- Dialog Anak -->
                                      <template v-slot:append-outer>
                                        <v-dialog
                                          v-model="DialogAnak"
                                          max-width="750px"
                                          scrollable
                                          hide-overlay
                                        >
                                          <template v-slot:activator="{on, attrs}">
                                            <v-btn
                                              dark
                                              x-small
                                              height="25"
                                              v-bind="attrs"
                                              v-on="on"
                                              color="red darken-4"
                                            >
                                              <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                          </template>
                                          <v-card outlined class="rounded-lg">
                                            <v-toolbar
                                              flat
                                            >
                                              <v-toolbar-title>
                                                <span class="headline"><label>{{ formTitleAnak }}</label></span>
                                              </v-toolbar-title>
                                              <v-spacer></v-spacer>
                                              <v-btn
                                                fab
                                                text
                                                small
                                                @click="KeluarDialogAnak"
                                              >
                                                <v-icon>mdi-window-close</v-icon>
                                              </v-btn>
                                            </v-toolbar>
                                            <v-divider></v-divider>
                                            
                                            <v-card-text>
                                              <div class="mr-n4 ml-n4">  
                                                <v-card outlined tile class="mt-2 mb-n3 mr-n2">  
                                                  <div class="pa-2">
                                                    <!-- Input Data Anak -->
                                                    <v-card 
                                                      tile
                                                      outlined
                                                      class="pa-4 mb-n3"
                                                    >
                                                      <div class="mt-3">
                                                        <v-row>
                                                          <v-col cols="12" sm="6" md="6">
                                                            <v-text-field
                                                              dense
                                                              label="Nama Anak"
                                                              color="dark"
                                                              v-model="Child.NamaAnak"
                                                            ></v-text-field>
                                                          </v-col>
                                                          
                                                          <v-col cols="12" sm="6" md="6">
                                                            <v-text-field
                                                              dense
                                                              label="AnakKe"
                                                              color="dark"
                                                              @change="checknum(Child.AnakKe)"
                                                              v-model="Child.AnakKe"
                                                            ></v-text-field>
                                                          </v-col>

                                                        </v-row>
                                                      </div>
                                                    </v-card>

                                                    <!-- Grid Tabel Anak -->
                                                    <v-card tile outlined class="mt-5 elevation-6">
                                                      <!-- <v-toolbar
                                                        dense
                                                        color="dark"
                                                      >
                                                        <span class="font-weight-bold">Anak</span>
                                                      </v-toolbar> -->
                                                      <div class="pa-2">
                                                        <ejs-grid
                                                          ref='gridanak'
                                                          height="130"
                                                          gridLines='Both'
                                                          id="anakview"
                                                          :dataSource="DataAnak"
                                                          :allowPaging="true"
                                                          :allowSorting='true'
                                                          :allowResizing='true'
                                                          :toolbar='toolbarOptions'
                                                          :commandClick="commandAnak"
                                                          :pageSettings='pageSettings'
                                                          :allowExcelExport='true'
                                                          :allowPdfExport='true'
                                                        >
                                                          <e-columns>
                                                            <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                                                              <div class="btn-group">
                                                                <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                                                                  <i class="fa fa-pencil"></i>
                                                                </v-btn>
                                                                <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                                                                  <i class="fa fa-trash"></i>
                                                                </v-btn>
                                                              </div>
                                                            </e-column>
                                                            <e-column field='AnakKe' headerText='AnakKe' textAlign='Left' width=110></e-column>
                                                            <e-column field='NamaAnak' headerText='Nama Anak' width=300></e-column>
                                                          </e-columns>
                                                        </ejs-grid>
                                                      </div>
                                                    </v-card>
                                                  </div>
                                                </v-card>
                                              </div>
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                              <span class="d-inline-block text-truncate">
                                                <label>Status : <span>{{StatusFormAnak}}</span></label>
                                              </span>
                                              <v-spacer></v-spacer> 
                                              <v-btn
                                                disabled
                                                class="text-capitalize mx-n2"
                                                v-show="Child.AnakKe.length == 0 || Child.NamaAnak.length == 0"
                                              >
                                                <v-icon left>mdi-content-save</v-icon>
                                                <span class="mr-1">Simpan</span>
                                              </v-btn>     
                                              <v-btn
                                                dark
                                                v-show="Child.AnakKe.length != 0 && Child.NamaAnak.length != 0"
                                                color="red darken-4 mx-n2"
                                                class="text-capitalize"
                                                @click="SimpanAnak"
                                              >
                                                <v-icon left>mdi-content-save</v-icon>
                                                <span class="mr-1">Simpan</span>
                                              </v-btn>
                                            </v-card-actions>
                                          </v-card>
                                        </v-dialog>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                              <v-card-actions>
                                <v-btn text small outlined class="mx-auto text-capitalize" @click="showData = false">
                                  Tutup
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-expand-transition>
                        </v-sheet>
                      </div>

                      <v-divider class="mt-2 mb-2"></v-divider>

                      <!-- Foto -->
                      <div>
                        <v-sheet outlined>
                          <a>
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <v-sheet
                                @click="showFoto = !showFoto"
                                :dark="hover ? true : false"
                                :color="hover ? 'grey darken-2' : '$vuetify.theme.dark'"
                                :class="hover == { 'on-hover': hover } == $vuetify.theme.dark ? 'grey lighten-4' : '$vuetify.theme.dark'"
                              >
                                <v-card-actions>
                                  <v-icon left>mdi-image</v-icon>
                                  <span class="subtitle-1 font-weight-medium">Foto</span>
                                  <v-spacer></v-spacer>
                                  <v-btn text small outlined class="text-capitalize">{{ showFoto ? 'Tutup' : 'Tampilkan' }}</v-btn>
                                </v-card-actions>
                              </v-sheet>
                            </v-hover>
                          </a>

                          <v-expand-transition transition="false">
                            <div v-show="showFoto">
                              <v-divider></v-divider>

                              <v-tabs
                                fixed-tabs
                                color="red darken-4"
                              >
                                <v-tabs-slider rounded="lg"></v-tabs-slider>
                                <v-tab @click="TabFotoKaryawan">Foto Karyawan</v-tab>
                                <v-tab @click="TabBPJS_TK">BPJS TK</v-tab>
                                <v-tab @click="TabBPJS_KES">BPJS KES</v-tab>
                                <v-tab @click="TabKTP">KTP</v-tab>
                                <v-tab @click="TabNPWP">NPWP</v-tab>
                              </v-tabs>

                              <v-divider></v-divider>

                              <v-tabs-items v-show="ShowTabFotoKaryawan">
                                <v-subheader class="title">Foto Karyawan</v-subheader>
                                <div class="pa-2 mt-n4">
                                  <v-col cols="12" sm="12" md="12">
                                    <div>
                                      <input
                                        id="upload-file"
                                        type="file"
                                        ref="fileupload"
                                        accept="image/png, image/jpeg"
                                        class="custom-file-input rounded-lg col-12"
                                        @change="fieldChange">
                                    </div>
                                  </v-col>

                                  <v-layout justify-center>
                                    <v-col cols="12" sm="6" md="6" lg="6">
                                      <v-card
                                        outlined
                                        class="pa-1 elevation-3"
                                      >
                                        <v-sheet
                                          outlined
                                          class="fill-height"
                                          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                                        >
                                          <v-responsive :aspect-ratio="3/4">
                                            <v-img
                                              :aspect-ratio="600/800"
                                              v-if="foto"
                                              :src="foto"
                                              class="d-flex flex-row fill-height"
                                            >
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height ma-0"
                                                  align="center"
                                                  justify="center"
                                                >
                                                  <!-- <v-progress-circular
                                                    size="70"
                                                    width="4"
                                                    indeterminate
                                                    color="grey lighten-5"
                                                  >
                                                    <v-icon large color="grey lighten-5">mdi-image</v-icon>
                                                  </v-progress-circular> -->
                                                  <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                                  <span class="subtitle-1">no image</span>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </v-responsive>
                                        </v-sheet>                                     
                                      </v-card>
                                    </v-col>
                                  </v-layout>
                                </div>
                              </v-tabs-items>

                              <v-tabs-items v-show="ShowTabBPJS_TK">
                                <v-subheader class="title">BPJS TK</v-subheader>
                                <div class="pa-2 mt-n4">
                                  <v-col cols="12" sm="12" md="12">
                                    <div>
                                      <input
                                        id="upload-file-bpjstk"
                                        type="file"
                                        ref="fileupload"
                                        accept="image/png, image/jpeg"
                                        class="custom-file-input rounded-lg col-12"
                                        @change="fieldChangeBpjsTK">
                                    </div>
                                  </v-col>

                                  <v-layout justify-center>
                                    <v-col cols="12" sm="10" md="10" lg="10">
                                      <v-card
                                        outlined
                                        class="pa-1 elevation-3"
                                      >
                                        <v-sheet
                                          outlined
                                          class="fill-height"
                                          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                                        >
                                          <v-responsive :aspect-ratio="4/2.4">
                                            <v-img
                                              :aspect-ratio="800/500"
                                              v-if="FotoBpjsTKObj"
                                              :src="FotoBpjsTKObj"
                                              class="d-flex flex-row fill-height"
                                            >
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height ma-0"
                                                  align="center"
                                                  justify="center"
                                                >
                                                  <!-- <v-progress-circular
                                                    size="70"
                                                    width="4"
                                                    indeterminate
                                                    color="grey lighten-5"
                                                  >
                                                    <v-icon large color="grey lighten-5">mdi-image</v-icon>
                                                  </v-progress-circular> -->
                                                  <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                                  <span class="subtitle-1">no image</span>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </v-responsive>
                                        </v-sheet>                                     
                                      </v-card>
                                    </v-col>
                                  </v-layout>
                                </div>
                              </v-tabs-items>

                              <v-tabs-items v-show="ShowTabBPJS_KES">
                                <v-subheader class="title">BPJS KES</v-subheader>
                                <div class="pa-2 mt-n4">
                                  <v-col cols="12" sm="12" md="12">
                                    <div>
                                      <input
                                        id="upload-file-bpjskes"
                                        type="file"
                                        ref="fileupload"
                                        accept="image/png, image/jpeg"
                                        class="custom-file-input rounded-lg col-12"
                                        @change="fieldChangeBpjsKes">
                                    </div>
                                  </v-col>

                                  <v-layout justify-center>
                                    <v-col cols="12" sm="10" md="10" lg="10">
                                      <v-card
                                        outlined
                                        class="pa-1 elevation-3"
                                      >
                                        <v-sheet
                                          outlined
                                          class="fill-height"
                                          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                                        >
                                          <v-responsive :aspect-ratio="4/2.4">
                                            <v-img
                                              :aspect-ratio="800/500"
                                              v-if="FotoBpjsKesObj"
                                              :src="FotoBpjsKesObj"
                                              class="d-flex flex-row fill-height"
                                            >
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height ma-0"
                                                  align="center"
                                                  justify="center"
                                                >
                                                  <!-- <v-progress-circular
                                                    size="70"
                                                    width="4"
                                                    indeterminate
                                                    color="grey lighten-5"
                                                  >
                                                    <v-icon large color="grey lighten-5">mdi-image</v-icon>
                                                  </v-progress-circular> -->
                                                  <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                                  <span class="subtitle-1">no image</span>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </v-responsive>
                                        </v-sheet>                                     
                                      </v-card>
                                    </v-col>
                                  </v-layout>
                                </div>
                              </v-tabs-items>

                              <v-tabs-items v-show="ShowTabKTP">
                                <v-subheader class="title">KTP</v-subheader>
                                <div class="pa-2 mt-n4">
                                  <v-col cols="12" sm="12" md="12">
                                    <div>
                                      <input
                                        id="upload-file-ktp"
                                        type="file"
                                        ref="fileupload"
                                        accept="image/png, image/jpeg"
                                        class="custom-file-input rounded-lg col-12"
                                        @change="fieldChangeKTP">
                                    </div>
                                  </v-col>

                                  <v-layout justify-center>
                                    <v-col cols="12" sm="10" md="10" lg="10">
                                      <v-card
                                        outlined
                                        class="pa-1 elevation-3"
                                      >
                                        <v-sheet
                                          outlined
                                          class="fill-height"
                                          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                                        >
                                          <v-responsive :aspect-ratio="4/2.4">
                                            <v-img
                                              :aspect-ratio="800/500"
                                              v-if="FotoKtpObj"
                                              :src="FotoKtpObj"
                                              class="d-flex flex-row fill-height"
                                            >
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height ma-0"
                                                  align="center"
                                                  justify="center"
                                                >
                                                  <!-- <v-progress-circular
                                                    size="70"
                                                    width="4"
                                                    indeterminate
                                                    color="grey lighten-5"
                                                  >
                                                    <v-icon large color="grey lighten-5">mdi-image</v-icon>
                                                  </v-progress-circular> -->
                                                  <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                                  <span class="subtitle-1">no image</span>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </v-responsive>
                                        </v-sheet>                                     
                                      </v-card>
                                    </v-col>
                                  </v-layout>
                                </div>
                              </v-tabs-items>

                              <v-tabs-items v-show="ShowTabNPWP">
                                <v-subheader class="title">NPWP</v-subheader>
                                <div class="pa-2 mt-n4">
                                  <v-col cols="12" sm="12" md="12">
                                    <div>
                                      <input
                                        id="upload-file-npwp"
                                        type="file"
                                        ref="fileupload"
                                        accept="image/png, image/jpeg"
                                        class="custom-file-input rounded-lg col-12"
                                        @change="fieldChangeNPWP">
                                    </div>
                                  </v-col>

                                  <v-layout justify-center>
                                    <v-col cols="12" sm="10" md="10" lg="10">
                                      <v-card
                                        outlined
                                        class="pa-1 elevation-3"
                                      >
                                        <v-sheet
                                          outlined
                                          class="fill-height"
                                          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                                        >
                                          <v-responsive :aspect-ratio="4/2.4">
                                            <v-img
                                              :aspect-ratio="800/500"
                                              v-if="FotoNPWPObj"
                                              :src="FotoNPWPObj"
                                              class="d-flex flex-row fill-height"
                                            >
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height ma-0"
                                                  align="center"
                                                  justify="center"
                                                >
                                                  <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                                  <span class="subtitle-1">no image</span>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </v-responsive>
                                        </v-sheet>                                     
                                      </v-card>
                                    </v-col>
                                  </v-layout>
                                </div>
                              </v-tabs-items>
  
                              <v-card-actions>
                                <v-btn text small outlined class="mx-auto text-capitalize" @click="showFoto = false">
                                  Tutup
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-expand-transition>
                        </v-sheet>
                      </div>

                      <v-divider class="mt-2 mb-2"></v-divider>

                      <!-- Kontrak -->
                      <div>
                        <v-sheet outlined>
                          <a>
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <v-sheet
                                @click="showKontrak = !showKontrak"
                                :dark="hover ? true : false"
                                :color="hover ? 'grey darken-2' : '$vuetify.theme.dark'"
                                :class="hover == { 'on-hover': hover } == $vuetify.theme.dark ? 'grey lighten-4' : '$vuetify.theme.dark'"
                              >
                                <v-card-actions >
                                  <v-icon left>mdi-file-document-edit</v-icon>
                                  <span class="subtitle-1 font-weight-medium">Kontrak</span>
                                  <v-spacer></v-spacer>
                                  <v-btn text small outlined class="text-capitalize">{{ showKontrak ? 'Tutup' : 'Tampilkan' }}</v-btn>
                                </v-card-actions>
                              </v-sheet>
                            </v-hover>
                          </a>

                          <v-expand-transition transition="false">
                            <div v-show="showKontrak">
                              <v-divider></v-divider>
                              <div class="pa-5">
                                <v-row>
                                  <!-- Kode karyawan -->
                                  <!-- <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      dense
                                      label="Kode karyawan"
                                      color="dark"
                                      v-model="editedItem.KodeKaryawan"
                                    ></v-text-field>
                                  </v-col> -->

                                  <!-- No Kontrak -->
                                  <v-col cols="12" sm="6" md="6" lg="6" v-show="formTitleKaryawan == 'Ubah Data Karyawan'">
                                    <v-text-field
                                      dense
                                      label="No Kontrak"
                                      color="dark"
                                      disabled
                                      hide-details="auto"
                                      class="font-weight-bold"
                                      v-model="Kontrak.NoKontrak"
                                    ></v-text-field>
                                  </v-col>

                                  <!-- TanggalMulai -->
                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-dialog
                                      v-model="MenuTglMulai"
                                      :return-value.sync="date"
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          :rules="[value => !!value || 'Tidak Boleh Kosong']"
                                          v-model="Kontrak.TanggalMulai"
                                          label="Tgl Mulai"
                                          prepend-icon="mdi-calendar"
                                          color="dark"
                                          readonly
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="Kontrak.TanggalMulai"
                                        @input="MenuTglMulai = false"
                                        year-icon="mdi-calendar-blank"
                                        color="red darken-4"
                                        class="rounded-lg"
                                        locale="id"
                                      ></v-date-picker>
                                    </v-dialog>
                                  </v-col>

                                  <!-- TanggalAkhir -->
                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-dialog
                                      v-model="MenuTglAkhir"
                                      :return-value.sync="date"
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          :rules="[value => !!value || 'Tidak Boleh Kosong']"
                                          v-model="Kontrak.TanggalAkhir"
                                          label="Tgl Akhir"
                                          prepend-icon="mdi-calendar"
                                          color="dark"
                                          readonly
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="Kontrak.TanggalAkhir"
                                        @input="MenuTglAkhir = false"
                                        year-icon="mdi-calendar-blank"
                                        color="red darken-4"
                                        class="rounded-lg"
                                        locale="id"
                                      ></v-date-picker>
                                    </v-dialog>
                                  </v-col>
                                </v-row>

                                <div class="text-center">
                                  <v-pagination
                                    v-model="pagekontrakkaryawan"
                                    :length="lengthKontrak"
                                    color="red darken-4"
                                    @input="gantiPage(pagekontrakkaryawan)"
                                    prev-icon="mdi-menu-left"
                                    next-icon="mdi-menu-right"
                                  ></v-pagination>
                                </div>
                              </div>
                              <v-card-actions>
                                <v-btn text small outlined class="mx-auto text-capitalize" @click="showKontrak = false">
                                  Tutup
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-expand-transition>
                        </v-sheet>
                      </div>

                      <v-divider class="mt-2 mb-2"></v-divider>

                      <!-- Jabatan -->
                      <div>
                        <v-sheet outlined>
                          <a>
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <v-sheet
                                @click="showJabatan = !showJabatan"
                                :dark="hover ? true : false"
                                :color="hover ? 'grey darken-2' : '$vuetify.theme.dark'"
                                :class="hover == { 'on-hover': hover } == $vuetify.theme.dark ? 'grey lighten-4' : '$vuetify.theme.dark'"
                              >
                                <v-card-actions >
                                  <v-icon left>mdi-account-hard-hat</v-icon>
                                  <span class="subtitle-1 font-weight-medium">Jabatan</span>
                                  <v-spacer></v-spacer>
                                  <v-btn text small outlined class="text-capitalize">{{ showJabatan ? 'Tutup' : 'Tampilkan' }}</v-btn>
                                </v-card-actions>
                              </v-sheet>
                            </v-hover>
                          </a>

                          <v-expand-transition transition="false">
                            <div v-show="showJabatan">
                              <v-divider></v-divider>
                              <div class="pa-5">
                                <v-col>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                      <v-text-field
                                        dense
                                        readonly
                                        label="Kode Jabatan"
                                        color="dark"
                                        class="mb-n5"
                                        :rules="[value => !!value || 'Wajib Diisi.']"
                                        v-model="editedItem.KodeJabatan"
                                      >
                                        <template v-slot:append-outer>
                                          <v-dialog
                                            v-model="DialogKodeJabatan"
                                            max-width="1000px"
                                            scrollable
                                            persistent
                                          >
                                            <template v-slot:activator="{on, attrs}">
                                              <v-btn
                                                dark
                                                x-small
                                                height="25"
                                                v-bind="attrs"
                                                v-on="on"
                                                color="red darken-4"
                                              >
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                              </v-btn>
                                            </template>
                                            <v-card class="rounded-lg">
                                              <v-toolbar
                                                flat
                                              >
                                                <v-toolbar-title>
                                                  <span class="headline"><label>{{ formTitleKodeJabatan }}</label></span>
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                  fab
                                                  text
                                                  small
                                                  @click="KeluarDialogKodeJabatan"
                                                >
                                                  <v-icon>mdi-window-close</v-icon>
                                                </v-btn>
                                              </v-toolbar>
                                              <v-divider></v-divider>
                                              <v-card-text>
                                                <div class="mb-n2 mr-n3 ml-n3">                                           
                                                  <v-card outlined class="rounded-lg mt-3 elevation-6">
                                                    <div class="pa-2">
                                                      <ejs-grid
                                                        ref='grikodejabatan'
                                                        height="208"
                                                        gridLines='Both'
                                                        id="kodejabatanview"
                                                        :dataSource="DataKodeJabatan"
                                                        :allowPaging="true"
                                                        :allowSorting='true'
                                                        :allowResizing='true'
                                                        :toolbar='toolbarOptions'
                                                        :recordDoubleClick="rowDblClickKodeJabatan"
                                                        :pageSettings='pageSettings'
                                                        :rowSelected="rowSelectedKodeJabatan" 
                                                      >
                                                        <e-columns>
                                                          <!-- <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                                                            <div class="btn-group">
                                                              <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                                                                <i class="fa fa-pencil"></i>
                                                              </v-btn>
                                                              <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                                                                <i class="fa fa-trash"></i>
                                                              </v-btn>
                                                            </div>
                                                          </e-column> -->
                                                          <e-column field='KodeJabatan' headerText='Kode Jabatan' textAlign='Left' width=150></e-column>
                                                          <e-column field='NamaJabatan' headerText='Nama Jabatan' width=300></e-column>
                                                          <e-column field='Departemen' headerText='Departemen' width=150></e-column>
                                                          <e-column field='Divisi' headerText='Divisi' width=200></e-column>
                                                          <e-column field='Atasan.NamaJabatan' headerText='Atasan' width=200></e-column>
                                                        </e-columns>
                                                      </ejs-grid>
                                                    </div>
                                                  </v-card>                                      
                                                </div>     
                                              </v-card-text>                                   
                                              <v-divider></v-divider>
                                              <v-card-actions>
                                                <span class="d-inline-block text-truncate">
                                                  <label>Status : <span>{{StatusFormKodeJabatan}}</span></label>
                                                </span>
                                              <v-spacer></v-spacer>
                                                <v-btn
                                                  dark
                                                  color="red darken-4 mx-n2"
                                                  class="text-capitalize"
                                                  @click="PilihKodeJabatan"
                                                >
                                                  <v-icon class="mr-1">mdi-check-circle</v-icon>
                                                  Pilih
                                                </v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </v-dialog>
                                        </template>
                                      </v-text-field>
                                    </v-col>

                                    <!-- Detail Jabatan -->
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                      <!-- Kondisi Mode Desktop -->
                                      <div v-show="mobile == false">
                                        <v-row dense no-gutters>
                                          <div class="col-6">
                                            <span class="subtitle-1 font-weight-bold">Nama Jabatan</span>
                                          </div>
                                          <div>
                                            <span class="subtitle-1">:
                                              <span class="subtitle-1" v-text="Jabatan.NamaJabatan"></span>
                                            </span>
                                          </div>
                                        </v-row>

                                        <v-row dense no-gutters>
                                          <div class="col-6">
                                            <span class="subtitle-1 font-weight-bold">Perusahaan</span>
                                          </div>
                                          <div>
                                            <span class="subtitle-1">:
                                              <span class="subtitle-1" v-text="Jabatan.KodePerusahaan.NamaPerusahaan"></span>
                                            </span>
                                          </div>
                                        </v-row>

                                        <v-row dense no-gutters>
                                          <div class="col-6">
                                            <span class="subtitle-1 font-weight-bold">Departemen</span>
                                          </div>
                                          <div>
                                            <span class="subtitle-1">:
                                              <span class="subtitle-1" v-text="Jabatan.Departemen"></span>
                                            </span>
                                          </div>
                                        </v-row>

                                        <v-row dense no-gutters>
                                          <div class="col-6">
                                            <span class="subtitle-1 font-weight-bold">Divisi</span>
                                          </div>
                                          <div>
                                            <span class="subtitle-1">:
                                              <span class="subtitle-1" v-text="Jabatan.Divisi"></span>
                                            </span>
                                          </div>
                                        </v-row>

                                        <v-row dense no-gutters>
                                          <div class="col-6">
                                            <span class="subtitle-1 font-weight-bold">Atasan</span>
                                          </div>
                                          <div>
                                            <span class="subtitle-1">:
                                              <span class="subtitle-1" v-text="Jabatan.Atasan != '-' ? Jabatan.Atasan.NamaJabatan : Jabatan.Atasan"></span>
                                            </span>
                                          </div>
                                        </v-row>
                                      </div>                                       
                                      <!-- -------------------- -->

                                      <!-- Kondisi Mode Mobile -->
                                      <div class="mt-n2 mb-n1" v-show="mobile == true">
                                        <div>
                                          <label class="subtitle-1 font-weight-bold">Nama Jabatan : </label>
                                        </div>
                                        <div class="mt-n1 mb-1">
                                          <label class="subtitle-1" v-text="Jabatan.NamaJabatan"></label>
                                        </div>

                                        <div>
                                          <label class="subtitle-1 font-weight-bold">Kode Perusahaan : </label>
                                        </div>
                                        <div class="mt-n1 mb-1">
                                          <label class="subtitle-1" v-text="Jabatan.KodePerusahaan"></label>
                                        </div>

                                        <div>
                                          <label class="subtitle-1 font-weight-bold">Departemen : </label>
                                        </div>
                                        <div class="mt-n1 mb-1">
                                          <label class="subtitle-1" v-text="Jabatan.Departemen"></label>
                                        </div>

                                        <div>
                                          <label class="subtitle-1 font-weight-bold">Divisi : </label>
                                        </div>
                                        <div class="mt-n1 mb-1">
                                          <label class="subtitle-1" v-text="Jabatan.Divisi"></label>
                                        </div>

                                        <div>
                                          <label class="subtitle-1 font-weight-bold">Atasan : </label>
                                        </div>
                                        <div class="mt-n1 mb-1">
                                          <label class="subtitle-1" v-text="Jabatan.Atasan"></label>
                                        </div>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </div>
                              <!-- ------------------- -->
                                
                              <div class="text-center">
                                <v-pagination
                                  v-model="pagejabatan"
                                  :length="lengthjabatan"
                                  color="red darken-4"
                                  prev-icon="mdi-menu-left"
                                  next-icon="mdi-menu-right"
                                ></v-pagination>
                              </div>
                            </div>
                          </v-expand-transition>
                        </v-sheet>
                      </div>

                      <v-divider class="mt-2 mb-2"></v-divider>

                      <!-- User -->
                      <div>
                        <v-sheet outlined>
                          <a>
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <v-sheet
                                @click="showUser = !showUser"
                                :dark="hover ? true : false"
                                :color="hover ? 'grey darken-2' : '$vuetify.theme.dark'"
                                :class="hover == { 'on-hover': hover } == $vuetify.theme.dark ? 'grey lighten-4' : '$vuetify.theme.dark'"
                              >
                                <v-card-actions>
                                  <v-icon left>mdi-account-circle</v-icon>
                                  <span class="subtitle-1 font-weight-medium">User</span>
                                  <v-spacer></v-spacer>
                                  <v-btn text small outlined class="text-capitalize">{{ showUser ? 'Tutup' : 'Tampilkan' }}</v-btn>
                                </v-card-actions>
                              </v-sheet>
                            </v-hover>
                          </a>

                          <v-expand-transition transition="false">
                            <div v-show="showUser">
                              <v-divider></v-divider>
                              <div class="pa-5">
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                      dense
                                      label="Kode"
                                      maxlength="15"
                                      v-show="false"
                                      color="dark"
                                      v-model="Kode"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                      dense
                                      label="Nama"
                                      color="dark"
                                      maxlength="10"
                                      counter
                                      :hint="alertNama"
                                      @keyup="cariKode(Nama)"
                                      v-model="Nama"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                      dense
                                      label="Password"
                                      color="dark"
                                      :disabled="textAktif"
                                      v-model="User.password"
                                      :append-icon="TampilkanPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                      @click:append="TampilkanPassword = !TampilkanPassword"
                                      :type="TampilkanPassword ? 'text' : 'password'"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <small class="mx-2">*tidak perlu di isi jika user sudah terdaftar</small>
                              </div>
                              <v-card-actions>
                                <v-btn text small outlined class="mx-auto text-capitalize" @click="showUser = false">
                                  Tutup
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-expand-transition>
                        </v-sheet>
                      </div>

                      <v-divider class="mt-2 mb-2"></v-divider>

                      <!-- Vaksin -->
                      <div>
                        <v-sheet outlined>
                          <a>
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <v-sheet
                                @click="showVaksin = !showVaksin"
                                :dark="hover ? true : false"
                                :color="hover ? 'grey darken-2' : '$vuetify.theme.dark'"
                                :class="hover == { 'on-hover': hover } == $vuetify.theme.dark ? 'grey lighten-4' : '$vuetify.theme.dark'"
                              >
                                <v-card-actions>
                                  <v-icon left>mdi-needle</v-icon>
                                  <span class="subtitle-1 font-weight-medium">Vaksin</span>
                                  <v-spacer></v-spacer>
                                  <v-btn text small outlined class="text-capitalize">{{ showVaksin ? 'Tutup' : 'Tampilkan' }}</v-btn>
                                </v-card-actions>
                              </v-sheet>
                            </v-hover>
                          </a>

                          <v-expand-transition transition="false">
                            <div v-show="showVaksin">
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-btn fab text outlined @click="tambahvaksin" v-show="mobile == true">
                                  <v-icon>mdi-plus-thick</v-icon>
                                </v-btn>

                                <v-btn text outlined class="ml-1 subtitle-1 text-capitalize" @click="tambahvaksin" v-show="mobile == false">
                                  <v-icon class="mr-1">mdi-plus-thick</v-icon>
                                  <span class="mr-1 subtitle-1">Tambah Data</span>
                                </v-btn>
                                <v-spacer></v-spacer>

                                <div class="text-right">
                                  <v-pagination
                                    v-model="pagevaksin"
                                    :length="lengthvaksin"
                                    @input="gantiPageVaksin(pagevaksin)"
                                    color="red darken-4"
                                    prev-icon="mdi-menu-left"
                                    next-icon="mdi-menu-right"
                                  ></v-pagination>
                                </div>
                              </v-card-actions>

                              <v-divider></v-divider>

                              <div class="pa-5" v-show="viewvaksin == true">
                                <v-row class="mt-1">
                                  <!-- <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      dense
                                      color="dark"
                                      label="Kode Karyawan"
                                      v-model="editedItem.KodeKaryawan"
                                    ></v-text-field>
                                  </v-col> -->

                                  <v-col cols="12" sm="6" md="6" lg="6" v-show="formTitleKaryawan == 'Ubah Data Karyawan'">
                                    <v-text-field
                                      dense
                                      color="dark"
                                      label="Vaksin Ke"
                                      v-model="Vaksin.VaksinKe"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-dialog
                                      v-model="MenuTglVaksin"
                                      :return-value.sync="date"
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          v-model="Vaksin.TanggalVaksin"
                                          label="Tgl Vaksin"
                                          prepend-icon="mdi-calendar"
                                          color="dark"
                                          readonly
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="Vaksin.TanggalVaksin"
                                        @input="MenuTglVaksin = false"
                                        year-icon="mdi-calendar-blank"
                                        color="red darken-4"
                                        class="rounded-lg"
                                        locale="id"
                                        scrollable
                                      ></v-date-picker>
                                    </v-dialog>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6" lg="6">
                                    <v-text-field
                                      dense
                                      color="dark"
                                      label="Keterangan"
                                      v-model="Vaksin.Keterangan"
                                    ></v-text-field>
                                  </v-col>

                                  <!-- Upload Sertifikat Vaksin -->
                                  <v-col cols="12" sm="12" md="12">
                                    <p class="mb-1 subtitle-1">Sertifikat Vaksin</p>
                                    <div class="mb-n3">
                                      <input
                                        id="upload-file-vaksin"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        ref="fileuploadsertifikat"
                                        class="custom-file-input rounded-lg col-12"
                                        @change="fieldChangeVaksin">
                                    </div>
                                  </v-col>

                                  <!-- Foto Sertifikat Vaksin -->
                                  <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto">
                                    <v-card
                                      tile
                                      outlined
                                      class="pa-1 elevation-3"
                                    >
                                      <v-card
                                        outlined
                                        class="fill-height"
                                        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                                      >
                                        <v-responsive :aspect-ratio="4/2.7">
                                          <v-img
                                            v-if="fotovaksin"
                                            :src="fotovaksin"
                                            class="d-flex flex-row fill-height"
                                          >
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                              >
                                                <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                                <span class="subtitle-1">no image</span>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-responsive>
                                      </v-card>                                     
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>
                              <v-card-actions>
                                <v-btn text small outlined class="mx-auto text-capitalize" @click="showVaksin = false">
                                  Tutup
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-expand-transition>
                        </v-sheet>
                      </div>
                    </v-sheet>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <span class="d-inline-block text-truncate">
                    <label>Status : <span>{{Status}}</span></label>
                  </span>
                <v-spacer></v-spacer>
                  <!-- <v-btn
                    dark
                    outlined
                    color="red darken-4"
                    class="text-capitalize mx-4"
                    @click="Reset"
                  >
                    <v-icon class="mr-1">mdi-close-circle</v-icon>
                    Batal
                  </v-btn> -->

                  <v-btn
                    dark
                    depressed
                    color="red darken-4 mr-n2"
                    class="text-capitalize"
                    @click="Simpan"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    <span class="mr-1">Simpan</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>

          <div v-show="loading == true">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              height="6"
              color="red darken-4"
            ></v-progress-linear>
          </div>
        </v-toolbar>

        <div>
          <ejs-grid
            ref='gridkaryawan'
            :filterSettings='FilterOptions'
            :allowFiltering='true'
            style="height: 100%;"
            height="43vh"
            gridLines='Both'
            id="karyawanview"
            :dataSource="DataKaryawan"
            :allowPaging="true"
            :allowSorting='true'
            :allowResizing='true'
            :allowGrouping='true'
            :toolbar='toolbarOptions'
            :commandClick="commandClick"
            :pageSettings='pageSettings'
            :allowExcelExport='true'
            :allowPdfExport='true'
          >
            <e-columns>
              <e-column headerText="Aksi" textAlign='Center' width="145" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                <div class="btn-group">
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                    <i class="fa fa-pencil"></i>
                  </v-btn>
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                    <i class="fa fa-trash"></i>
                  </v-btn>
                </div>
              </e-column>
              <e-column field='KodeKaryawan' headerText='Kode' textAlign='Left' width=120></e-column>
              <e-column field='Nama' headerText='Nama' width=300></e-column>
              <e-column field='NRK' headerText='NRK' textAlign='Left' width=150></e-column>
              <e-column field='KodeJabatan' headerText='Kode Jabatan' :visible = 'false' textAlign='Left' width=150></e-column>
              <e-column field='jabatan.NamaJabatan' headerText='Jabatan' textAlign='Left' width=150></e-column>
              <e-column field='POH' :filter="filter" headerText='POH' textAlign='Left' width=150></e-column>
              <e-column field='TglMasuk' headerText='Tgl Masuk' textAlign='Left' width=150></e-column>
              <e-column field='TempatLahir' :filter="filter" headerText='Tempat Lahir' textAlign='Left' width=150></e-column>
              <e-column field='TglLahir' headerText='Tgl Lahir' textAlign='Left' width=150></e-column>
              <e-column field='AlamatKTP' headerText='Alamat KTP' textAlign='Left' width=200></e-column>
              <e-column field='AlamatSekarang' headerText='Alamat Sekarang' textAlign='Left' width=200></e-column>
              <e-column field='NoTlp' headerText='No. Tlp' textAlign='Left' width=150></e-column>
              <e-column field='NoTlpEmergency' headerText='No. Tlp Emergency' textAlign='Left' width=150></e-column>
              <e-column field='JenisKelamin' :filter="filter" headerText='Jenis Kelamin' textAlign='Left' width=150></e-column>
              <e-column field='PendidikanTerakhir' :filter="filter" headerText='Pendidikan Terakhir' textAlign='Left' width=190></e-column>
              <e-column field='Jurusan' :filter="filter" headerText='Jurusan' textAlign='Left' width=150></e-column>
              <e-column field='Agama' :filter="filter" headerText='Agama' textAlign='Left' width=150></e-column>
              <e-column field='Suku' :filter="filter" headerText='Suku' textAlign='Left' width=150></e-column>
              <e-column field='NoRekTabungan' headerText='No. RekTabungan' textAlign='Left' width=180></e-column>
              <e-column field='NoKartuBPJSTK' headerText='No. Kartu BPJS TK' textAlign='Left' width=180></e-column>
              <e-column field='NoKartuBPJSKes' headerText='No. Kartu BPJS Kes' textAlign='Left' width=180></e-column>
              <e-column field='NoKTP' headerText='No. KTP' textAlign='Left' width=180></e-column>
              <e-column field='NoNPWP' headerText='No. NPWP' textAlign='Left' width=180></e-column>
              <e-column field='Cuti' headerText='Sisa Cuti' textAlign='Left' width=150></e-column>
              <e-column field='GolDarah' :filter="filter" headerText='Golongan Darah' textAlign='Left' width=150></e-column>
              <e-column field='StatusPerkawinan' :filter="filter" headerText='Status' textAlign='Left' width=150></e-column>
              <e-column field='StatusKerja' ref="StatusKerja" :filter="filter" headerText='Status Kerja' textAlign='Left' width=150></e-column>
              <e-column field='UkuranBaju' headerText='Ukuran Baju' textAlign='Left' width=150></e-column>
              <e-column field='UkuranSepatu' headerText='Ukuran Sepatu' textAlign='Left' width=150></e-column>
              <e-column field='PHK' headerText='PHK' textAlign='Left' width=150></e-column>
              <e-column field='TglPHK' headerText='Tgl PHK' textAlign='Left' width=150></e-column>
              <e-column field='Keterangan' headerText='Keterangan' textAlign='Left' width=150></e-column>
              <e-column field='Photo' headerText='Photo' textAlign='Left' width=150></e-column>
              <e-column field='NamaIstriSuami' headerText='Nama Istri Suami' textAlign='Left' width=150></e-column>
              <e-column field='cabang.NamaCabang' :filter="filter" headerText='Cabang' textAlign='Left' width=150></e-column>
              <e-column field='Lokasi' :filter="filter" headerText='Lokasi' textAlign='Left' width=150></e-column>
              <e-column field='DibuatOleh' headerText='DibuatOleh' textAlign='Left' width=150></e-column>
              <e-column field='DibuatTgl' headerText='DibuatTgl' textAlign='Left' width=150></e-column>
              <e-column field='DiubahOleh' headerText='DiubahOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiubahTgl' headerText='DiubahTgl' textAlign='Left' width=150></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-sheet>
    </v-container>

    <!-- dialog ganti photo karyawan-->
    <v-dialog v-model="dialogchangefoto" max-width="405">
      <v-card class="rounded-lg">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="headline">Yakin Mengganti Foto Karyawan?</span> 
          <v-spacer></v-spacer>                   
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="GantiGambar = false, dialogchangefoto = false, foto = fotoTemp"
          >
            Tidak
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="gantifotokaryawan"
          >
            Ya
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog ganti photo bpjstk-->
    <v-dialog v-model="dialogchangebpjstk" max-width="405">
      <v-card class="rounded-lg">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="headline">Yakin Mengganti Foto BPJS TK?</span> 
          <v-spacer></v-spacer>                   
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="GantiGambar = false, dialogchangefoto = false, foto = fotoTemp"
          >
            Tidak
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="gantifotobpjstk"
          >
            Ya
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog ganti photo bpjskes-->
    <v-dialog v-model="dialogchangebpjskes" max-width="405">
      <v-card class="rounded-lg">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="headline">Yakin Mengganti Foto BPJS KES?</span> 
          <v-spacer></v-spacer>                   
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="GantiGambar = false, dialogchangebpjskes = false, foto = fotoTemp"
          >
            Tidak
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="gantifotobpjskes"
          >
            Ya
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog ganti photo ktp-->
    <v-dialog v-model="dialogchangektp" max-width="405">
      <v-card class="rounded-lg">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="headline">Yakin Mengganti Foto KTP?</span> 
          <v-spacer></v-spacer>                   
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="GantiGambar = false, dialogchangektp = false, foto = fotoTemp"
          >
            Tidak
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="gantifotoKTP"
          >
            Ya
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog ganti photo npwp-->
    <v-dialog v-model="dialogchangenpwp" max-width="405">
      <v-card class="rounded-lg">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="headline">Yakin Mengganti Foto NPWP?</span> 
          <v-spacer></v-spacer>                   
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="GantiGambar = false, dialogchangenpwp = false, foto = fotoTemp"
          >
            Tidak
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="gantifotoNPWP"
          >
            Ya
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog ganti photo sertifikat vaksin -->
    <v-dialog v-model="dialogchangesertifikat" max-width="405">
      <v-card class="rounded-lg">
        <v-card-title>
          <v-spacer></v-spacer>
          <span class="headline">Yakin Mengganti Foto Sertifikat?</span> 
          <v-spacer></v-spacer>                   
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="GantiGambar = false, dialogchangesertifikat = false, fotovaksin = fotovaksinTemp"
          >
            Tidak
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="dark"
            text
            @click="gantifotovaksin"
          >
            Ya
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog lihat data karyawan per karyawan -->
    <v-dialog   
      scrollable
      max-width="1000"
      v-model="DialogLihatDataKaryaran"
      :fullscreen="mobile ? true : false"
      :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
    >
      <v-card outlined class="rounded-lg">
        <v-toolbar flat height="64">
          <v-btn
            icon
            v-show="mobile == true"
            @click="DialogLihatDataKaryaran = false"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>                
            <span class="title ml-n4">Detail Karyawan</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            v-show="mobile == false"
            @click="DialogLihatDataKaryaran = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <!-- Mode Desktop Start -->
        <v-card-text
          v-show="mobile == false"
        > 
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <!-- <v-container height="800px" class="fill-height">
              <v-sheet outlined :color="$vuetify.theme.dark ? '' : 'white'" class="rounded-circle elevation-3 pa-1">
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    size="52"
                    width="6"
                    class="fill-height ma-0"
                    color="error"
                  >
                    <v-img
                      width="40px"
                      class="mx-n2"
                      src="../assets/LogoTrinity.png"
                    ></v-img>
                  </v-progress-circular>
                </v-row>
              </v-sheet>
            </v-container> -->
            <v-sheet
              outlined
              class="rounded-lg pa-5"
            >
              <v-row no-gutters>
                <div style="width: 78%;">
                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Nama</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Nama" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-5 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">NRK</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.NRK" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Jabatan</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.jabatan.NamaJabatan" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">POH</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.POH" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Lokasi</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Lokasi" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-6 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Cabang</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Cabang" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-6 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tanggal Masuk</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.TglMasuk" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Masa Kerja</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="MasaKerja" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-6 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tempat Lahir</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.TempatLahir" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tanggal Lahir</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.TglLahir" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Alamat KTP</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 63%;" v-text="editedItem.AlamatKTP" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-6 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Alamat Sekarang</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 63%;" v-text="editedItem.AlamatSekarang" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-6 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Telp</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.NoTlp" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Telp Emergency</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.NoTlpEmergency" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Jenis Kelamin</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.JenisKelamin" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-2 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Pendidikan Terakhir</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.PendidikanTerakhir" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Jurusan</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Jurusan" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Agama</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Agama" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Suku</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Suku" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Rek. Tabungan</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.NoRekTabungan" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Kartu BPJS TK</label>
                      <label style="width: 2%;">:</label>
                      <v-dialog
                        v-model="DialogLihatKartuBPJSTK"
                        width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="loading_show_data_karyawan == false"
                            x-small
                            class="mt-1"
                            v-bind="attrs"
                            v-on="on"
                            style="margin-right: 10px;"
                            :outlined="$vuetify.theme.dark ? true : false"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>

                        <v-card tile outlined class="pa-1">
                          <v-card
                            tile
                            outlined
                            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                            class="fill-height"
                          >
                            <v-responsive :aspect-ratio="4/2.4">
                              <v-img
                                :aspect-ratio="800/500"
                                v-if="FotoBpjsTKObj"
                                :src="FotoBpjsTKObj"
                                class="d-flex flex-row fill-height"
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    x-small
                                    @click="DialogLihatKartuBPJSTK = false"
                                  >
                                    <v-icon>mdi-close-thick</v-icon>
                                  </v-btn>
                                </v-card-actions>
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <!-- <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular> -->
                                    <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                    <span class="subtitle-1">no image</span>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-responsive>  
                          </v-card>
                        </v-card>
                      </v-dialog>
                      <label style="width: auto;" v-text="editedItem.NoKartuBPJSTK" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Kartu BPJS KES</label>
                      <label style="width: 2%;">:</label>
                      <v-dialog
                        v-model="DialogLihatKartuBPJSKES"
                        max-width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="loading_show_data_karyawan == false"
                            x-small
                            class="mt-1"
                            v-bind="attrs"
                            v-on="on"
                            style="margin-right: 10px;"
                            :outlined="$vuetify.theme.dark ? true : false"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>

                        <v-card tile outlined class="pa-1">
                          <v-card
                            tile
                            outlined
                            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                            class="fill-height"
                          >
                            <v-responsive :aspect-ratio="4/2.4">
                              <v-img
                                :aspect-ratio="800/500"
                                v-if="FotoBpjsKesObj"
                                :src="FotoBpjsKesObj"
                                class="d-flex flex-row fill-height"
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    x-small
                                    @click="DialogLihatKartuBPJSKES = false"
                                  >
                                    <v-icon>mdi-close-thick</v-icon>
                                  </v-btn>
                                </v-card-actions>
                                
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <!-- <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular> -->
                                    <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                    <span class="subtitle-1">no image</span>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-responsive>  
                          </v-card>
                        </v-card>
                      </v-dialog>
                      <label style="width: auto;" v-text="editedItem.NoKartuBPJSKes" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. KTP</label>
                      <label style="width: 2%;">:</label>
                      <v-dialog
                        v-model="DialogLihatKTP"
                        max-width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="loading_show_data_karyawan == false"
                            x-small
                            class="mt-1"
                            v-bind="attrs"
                            v-on="on"
                            style="margin-right: 10px;"
                            :outlined="$vuetify.theme.dark ? true : false"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>

                        <v-card tile outlined class="pa-1">
                          <v-card
                            tile
                            outlined
                            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                            class="fill-height"
                          >
                            <v-responsive :aspect-ratio="4/2.4">
                              <v-img
                                :aspect-ratio="800/500"
                                v-if="FotoKtpObj"
                                :src="FotoKtpObj"
                                class="d-flex flex-row fill-height"
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    x-small
                                    @click="DialogLihatKTP = false"
                                  >
                                    <v-icon>mdi-close-thick</v-icon>
                                  </v-btn>
                                </v-card-actions>
                                
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <!-- <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular> -->
                                    <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                    <span class="subtitle-1">no image</span>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-responsive>  
                          </v-card>
                        </v-card>
                      </v-dialog>
                      <label style="width: auto%;" v-text="editedItem.NoKTP" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. NPWP</label>
                      <label style="width: 2%;">:</label>
                      <v-dialog
                        v-model="DialogLihatKartuNPWP"
                        max-width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="loading_show_data_karyawan == false"
                            x-small
                            class="mt-1"
                            v-bind="attrs"
                            v-on="on"
                            style="margin-right: 10px;"
                            :outlined="$vuetify.theme.dark ? true : false"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>

                        <v-card tile outlined class="pa-1">
                          <v-card
                            tile
                            outlined
                            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                            class="fill-height"
                          >
                            <v-responsive :aspect-ratio="4/2.4">
                              <v-img
                                :aspect-ratio="800/500"
                                v-if="FotoNPWPObj"
                                :src="FotoNPWPObj"
                                class="d-flex flex-row fill-height"
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    x-small
                                    @click="DialogLihatKartuNPWP = false"
                                  >
                                    <v-icon>mdi-close-thick</v-icon>
                                  </v-btn>
                                </v-card-actions>
                                
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <!-- <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular> -->
                                    <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                    <span class="subtitle-1">no image</span>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-responsive>  
                          </v-card>
                        </v-card>
                      </v-dialog>
                      <label style="width: auto%;" v-text="editedItem.NoNPWP" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Golongan Darah</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.GolDarah" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-1 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Status</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.StatusPerkawinan" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Status Kerja</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.StatusKerja" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Ukuran Baju</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.UkuranBaju" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-2 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Ukuran Sepatu</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.UkuranSepatu" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-2 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tanggal PHK</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.TglPHK" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-3 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Keterangan</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Keterangan" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Nama Istri Suami</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.NamaIstriSuami" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Anak</label>
                      <label style="width: 2%;">:</label>
                      <v-dialog
                        v-model="DialogLihatDetailAnakModeDesktop"
                        scrollable
                        max-width="600px"
                        style="width: 10%;"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="loading_show_data_karyawan == false"
                            style="margin-right: 10px;"
                            x-small
                            class="mt-1"
                            v-bind="attrs"
                            v-on="on"
                            :outlined="$vuetify.theme.dark ? true : false"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-card
                          outlined
                          class="rounded-lg"
                        >
                          <v-toolbar
                            flat
                            height="64px"
                          >
                            <v-toolbar-title class="text-h5">
                              Detail Anak
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="DialogLihatDetailAnakModeDesktop = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <v-divider></v-divider>

                          <v-card-text>
                            <div class="mr-n3 ml-n3 mb-n2 mt-3"> 
                              <v-sheet
                                outlined
                                class="rounded-lg"
                              >
                                <v-simple-table width="100%" class="rounded-lg">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="subtitle-1 font-weight-bold" style="width: 30%;">Anak Ke</th>
                                        <th class="subtitle-1 font-weight-bold" style="width: 70%;">Nama Anak</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-for="(Anak,index) in DataAnak"
                                        :key="index"
                                      >
                                        <td v-text="Anak.AnakKe">-</td>
                                        <td v-text="Anak.NamaAnak">-</td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-sheet>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                      <label style="width: auto;" v-text="editedItem.Anak" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-2 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Vaksin</label>
                      <label style="width: 2%;">:</label>
                      <v-dialog
                        v-model="DialogLihatDetailVaksin"
                        scrollable
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="loading_show_data_karyawan == false"
                            style="margin-right: 10px;"
                            x-small
                            class="mt-1"
                            v-bind="attrs"
                            v-on="on"
                            :outlined="$vuetify.theme.dark ? true : false"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>

                        <v-card
                          outlined
                          class="rounded-lg"
                        >
                          <v-toolbar flat height="64px">
                            <v-toolbar-title class="text-h5">
                              Detail Vaksin
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="DialogLihatDetailVaksin = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-divider></v-divider>
                          <v-card-text>
                            <div class="mr-n3 ml-n3 mb-n2 mt-3"> 
                              <v-sheet outlined class="rounded-lg">                                                                        
                                <div class="text-right">
                                  <v-pagination
                                    v-model="pagevaksin"
                                    :length="lengthvaksin"
                                    @input="gantiPageVaksin(pagevaksin)"
                                    color="red darken-4"
                                    prev-icon="mdi-menu-left"
                                    next-icon="mdi-menu-right"
                                  ></v-pagination>
                                </div>

                                <v-divider></v-divider>
                                  
                                <div class="pa-3">
                                  <div>
                                    <v-row no-gutters class="subtitle-1">
                                      <label style="width: 49%;">Vaksin Ke</label>
                                      <label style="width: 2%;">:</label>
                                      <label style="width: 49%;" v-text="Vaksin.VaksinKe"></label>
                                    </v-row>
                                  </div>

                                  <div>
                                    <v-row no-gutters class="subtitle-1">
                                      <label style="width: 49%;">Tanggal Vaksin</label>
                                      <label style="width: 2%;">:</label>
                                      <label style="width: 49%;" v-text="Vaksin.TanggalVaksin"></label>
                                    </v-row>
                                  </div>

                                  <div>
                                    <v-row no-gutters class="subtitle-1">
                                      <label style="width: 49%;">Keterangan</label>
                                      <label style="width: 2%;">:</label>
                                      <label style="width: 49%;" v-text="Vaksin.Keterangan"></label>
                                    </v-row>
                                  </div>

                                  <div>
                                    <v-row no-gutters class="subtitle-1">
                                      <label style="width: 49%;">Sertifikat</label>
                                      <label style="width: 2%;">:</label>
                                      <label style="width: auto;"></label>
                                      <v-dialog
                                        v-model="DialogLihatSertifikatVaksin"
                                        max-width="600"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            x-small
                                            class="mt-1"
                                            v-bind="attrs"
                                            v-on="on"
                                            style="margin-left: 5px;"
                                            :outlined="$vuetify.theme.dark ? true : false"
                                          >
                                            <!-- <v-icon>mdi-dots-horizontal</v-icon> -->
                                            Lihat
                                          </v-btn>
                                        </template>

                                        <v-card tile outlined class="pa-1">
                                          <v-card
                                            tile
                                            outlined
                                            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                                            class="fill-height"
                                          >
                                            <v-responsive :aspect-ratio="4/2.7">
                                              <v-img
                                                v-if="fotovaksin"
                                                :src="fotovaksin"
                                                class="d-flex flex-row fill-height"
                                              >
                                                <v-card-actions>
                                                  <v-spacer></v-spacer>
                                                  <v-btn
                                                    fab
                                                    x-small
                                                    @click="DialogLihatSertifikatVaksin = false"
                                                  >
                                                    <v-icon>mdi-close-thick</v-icon>
                                                  </v-btn>
                                                </v-card-actions>
                                                
                                                <template v-slot:placeholder>
                                                  <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                  >
                                                    <!-- <v-progress-circular
                                                      indeterminate
                                                      color="grey lighten-5"
                                                    ></v-progress-circular> -->
                                                    <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                                    <span class="subtitle-1">no image</span>
                                                  </v-row>
                                                </template>
                                              </v-img>
                                            </v-responsive>  
                                          </v-card>
                                        </v-card>
                                      </v-dialog>
                                    </v-row>
                                  </div>
                                </div>
                              </v-sheet>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                      <label style="width: auto" v-text="editedItem.vaksin.length == 0 ? 'Belum ada Vaksin' : 'Sudah di Vaksin'" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-2 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Sisa Cuti</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="editedItem.Cuti" v-show="loading_show_data_karyawan == false"></label>
                      <v-skeleton-loader height="16px" class="col-4 mt-2 rounded-lg" type="image" v-show="loading_show_data_karyawan == true"></v-skeleton-loader>
                    </v-row>
                  </div>
                </div>

                <div style="width: 22%;">
                  <div>
                    <v-card tile outlined class="pa-1 elevation-4">
                      <v-card
                        tile
                        outlined
                        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                        class="fill-height"
                      >
                        <v-responsive :aspect-ratio="3/4.3">
                          <v-img
                            v-if="foto"
                            :src="foto"
                            class="d-flex flex-row fill-height"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <!-- <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular> -->
                                <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                <span class="subtitle-1">no image</span>
                              </v-row>
                            </template>
                          </v-img>
                        </v-responsive>  
                      </v-card>
                    </v-card>
                  </div>
                </div>
              </v-row>
            </v-sheet>
          </div>
        </v-card-text>
        <!-- Mode Desktop End -->

        <!-- Mode Mobile Start -->
        <v-card-text
          v-show="mobile == true"
          class="ScrollCSSMobile"
          :class="$vuetify.theme.dark ? '' : 'white'"
        > 
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-sheet outlined class="rounded-lg">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Nama</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.Nama"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>NRK</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NRK"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>POH</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.POH.length"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Lokasi</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.Lokasi"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Tanggal Masuk</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.TglMasuk"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Masa Kerja</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="MasaKerja+ ' Hari'"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Tempat Lahir</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.TempatLahir"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Tanggal Lahir</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.TglLahir"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Alamat KTP</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.AlamatKTP"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Alamat Sekarang</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.AlamatSekarang"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>No. Telp</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NoTlp"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>No. Telp Emergency</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NoTlpEmergency"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Jenis Kelamin</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.JenisKelamin"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Pendidikan Terakhir</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.PendidikanTerakhir"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Jurusan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.Jurusan"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Agama</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.Agama"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Suku</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.Suku"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>No. Rek. Tabungan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NoRekTabungan"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content @click="DialogLihatKartuBPJSTK = true">
                  <v-list-item-title><strong>No. Kartu BPJS TK</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NoKartuBPJSTK"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="DialogLihatKartuBPJSTK = true" text small class="mr-n1">
                    <strong>Lihat</strong>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content @click="DialogLihatKartuBPJSKES = true">
                  <v-list-item-title><strong>No. Kartu BPJS KES</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NoKartuBPJSKes"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="DialogLihatKartuBPJSKES = true" text small class="mr-n1">
                    <strong>Lihat</strong>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content @click="DialogLihatKTP = true">
                  <v-list-item-title><strong>No. KTP</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NoKTP"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="DialogLihatKTP = true" text small class="mr-n1">
                    <strong>Lihat</strong>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content @click="DialogLihatKartuNPWP = true">
                  <v-list-item-title><strong>No. NPWP</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NoNPWP"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="DialogLihatKartuNPWP = true" text small class="mr-n1">
                    <strong>Lihat</strong>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Golongan Darah</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.GolDarah"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Status</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.status"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Status Kerja</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.StatusKerja"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Ukuran Baju</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.UkuranBaju"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Ukuran Sepatu</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.UkuranSepatu"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>PHK</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.PHK"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Tanggal PHK</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.TglPHK"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Keterangan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.Keterangan"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Nama Istri Suami</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.NamaIstriSuami"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content @click="DialogLihatDetailAnak = true">
                  <v-list-item-title><strong>Anak</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="editedItem.Anak"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    text
                    small
                    class="mr-n1"
                    @click="DialogLihatDetailAnak = true"
                  >
                    <strong>Lihat</strong>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content @click="DialogLihatDetailVaksin = true">
                  <v-list-item-title><strong>Vaksin</strong></v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="subtitle-1" v-text="editedItem.vaksin.length == 0 ? 'Belum ada Vaksin' : 'Sudah di Vaksin'"></span>
                    <v-icon class="ml-1" color="success" small v-text="editedItem.vaksin.length == 0 ? '' : 'mdi-checkbox-marked-circle'"></v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="DialogLihatDetailVaksin = true" text small class="mr-n1">
                    <strong>Lihat</strong>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-sheet>
          </div>
        </v-card-text>
        <!-- Mode Mobile End -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search,CommandColumn,ExcelExport,PdfExport,Filter } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return { 
      ShowTabFotoKaryawan: true,
      ShowTabBPJS_TK: false,
      ShowTabBPJS_KES: false,
      ShowTabKTP: false,
      ShowTabNPWP: false,

      loading_show_data_karyawan: true,
      DialogLihatKTP: false,
      DialogLihatKartuNPWP: false,
      DialogLihatSertifikatVaksin: false,
      DialogLihatDetailVaksin: false,
      DialogLihatDetailAnakModeDesktop: false,
      DialogLihatKartuBPJSTK: false,
      DialogLihatKartuBPJSKES: false,
      DialogLihatDataKaryaran: false,
      DataCabang: [],
      anak:[],
      show:false,
      pagekontrakkaryawan: 1,
      MasaKerja:null,
      pagevaksin:1,
      lengthKontrak:1,
      pagejabatan:1,
      lengthjabatan:1,
      lengthvaksin:1,
      viewvaksin: true,
      // server:process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+":8000" : location.protocol+'://'+window.location.hostname+'/tss/public',
      // server:process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+":8000" : window.location.hostname+'/tss/public',
      server:process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+":8000" : location.origin+'/tss/public',
      //---------https------
      // server:"https://192.168.1.189/tss/public",
      // server:"https://147.139.189.197/tss/public",
      // server:"https://trinityauto.web.id/tss/public",
      //---------http-------
      // server:"http://192.168.1.189/tss/public",
      // server:"http://"+window.location.hostname+"/tss/public",
      // server:"http://"+window.location.hostname+":8000",
      TampilNama: '',
      closeOnContentClick: true,  
      Permission:[],
      ValidCreate:false,
      showData: false,
      showFoto: false,
      showKontrak: false,
      showJabatan: false,
      showUser:false,
      showVaksin: false,
      textAktif:true,
      TampilkanPassword:false,
      mobile:null,
      valid:null,
      loading:true,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      Nama:null,
      alertNama:'',
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      date: new Date().toISOString().substr(0, 10),
      MenuTglMulai: false,
      MenuTglAkhir: false,
      MenuTglMasuk: false,
      MenuTglLahir: false,
      MenuTglVaksin: false,
      MenuTglPHK: false,
      user:[],
      DataUser:[],
      UserMenuItems:[],
      token:null,
      arr:null,
      KodeKaryawan:null,
      DialogTambahKaryawan: false,
      DialogKodeJabatan: false,
      DialogAnak: false,
      AnakKeTemp:null,
      form: new FormData,
      editedIndex: -1,
      ChildIndex: -1,
      dialogchangesertifikat:false,
      DataKategoriAbsen:[],
      FotoKaryawan:[],
      FotoBpjsTK:[],
      FotoBpjsKes:[],
      FotoNPWP:[],
      FotoKTP:[],
      DataFotoVaksin:[],
      fotoTemp:null,
      fotovaksinTemp:null,
      Kode:null,
      DataAnak:[],
      DataKodeJabatan:[],
      foto: null,
      FotoBpjsTKObj:null,
      FotoBpjsKesObj:null,
      FotoNPWPObj:null,
      FotoKtpObj:null,
      fotovaksin:null,
      KodeKaryawanTemp:null,
      dialogchangefoto:false,
      dialogchangebpjstk:false,
      dialogchangebpjskes:false,
      dialogchangektp:false,
      dialogchangenpwp:false,
      GantiGambar:null,
      FilterOptions: {
        type: 'Menu'
      },
      filter: {
        type : 'CheckBox'
      },
      Child: {
        NamaAnak: "",
        AnakKe: ""
      },
      ChildDefault: {
        NamaAnak: "",
        AnakKe: ""
      },

      Kontrak: {
        NoKontrak: 1,
        TanggalMulai: "",
        TanggalAkhir: "",
      },
      KontrakDefault: {
        NoKontrak: 1,
        TanggalMulai: "",
        TanggalAkhir: "",
      },
      Jabatan: {
        NamaJabatan: "",
        NoUrut:"",
        KodePerusahaan: "",
        Departemen: "",
        Divisi : "",
        Atasan: "",
        TglMulai:"",
        TglSelesai:"",
      },
      JabatanDefault: {
        NamaJabatan: "",
        NoUrut:"",
        KodePerusahaan: "",
        Departemen: "",
        Divisi : "",
        Atasan: "",
        TglMulai:"",
        TglSelesai:"",
      },
      User: {
        Kode: "",
        Nama: "",
        password: "",
      },
      UserDefault: {
        Kode: "",
        Nama: "",
        password: "",
      },
      Vaksin: {
        VaksinKe: "1",
        TanggalVaksin: "",
        Keterangan: "",
        Sertifikat:"",
      },
      VaksinDefault: {
        VaksinKe: "1",
        TanggalVaksin: "",
        Keterangan: "",
        Sertifikat:"",
      },

      defaultItem: {
        id:"",
        KodeKaryawan: "",
        Nama: "",
        NRK: "",
        KodeJabatan: "",
        POH: "",
        TglMasuk: "",
        TempatLahir: "",
        TglLahir: "",
        AlamatKTP: "",
        AlamatSekarang: "",
        NoTlp: "",
        NoTlpEmergency: "",
        JenisKelamin: "",
        PendidikanTerakhir: "",
        Jurusan: "",
        Agama: "",
        Suku: "",
        NoRekTabungan: "",
        NoKartuBPJSTK: "",
        NoKartuBPJSKes: "",
        NoKTP: "",
        NoNPWP: "",
        Cuti:"",
        GolDarah: "",
        StatusPerkawinan: "",
        StatusKerja: "",
        UkuranBaju: "",
        UkuranSepatu: "",
        PHK: "",
        TglPHK: "",
        Keterangan: "",
        Photo: "",
        NamaIstriSuami: "",
        Anak:"",
        Cabang:"",
        KategoriAbsen:"",
        Lokasi:"",
        anak:[],
        jabatan:[],
        kontrak:[],
        vaksin:[],
      },

      editedItem: {
        id:"",
        KodeKaryawan: "",
        Nama: "",
        NRK: "",
        KodeJabatan: "",
        POH: "",
        TglMasuk: "",
        TempatLahir: "",
        TglLahir: "",
        AlamatKTP: "",
        AlamatSekarang: "",
        NoTlp: "",
        NoTlpEmergency: "",
        JenisKelamin: "",
        PendidikanTerakhir: "",
        Jurusan: "",
        Agama: "",
        Suku: "",
        NoRekTabungan: "",
        NoKartuBPJSTK: "",
        NoKartuBPJSKes: "",
        NoKTP: "",
        NoNPWP: "",
        Cuti:"",
        GolDarah: "",
        StatusPerkawinan: "",
        StatusKerja: "",
        UkuranBaju: "",
        UkuranSepatu: "",
        PHK: "",
        TglPHK: "",
        Keterangan: "",
        Photo: "",
        NamaIstriSuami: "",
        Anak:"",
        Cabang:"",
        KategoriAbsen:"",
        anak:[],
        Lokasi:"",
        jabatan:[],
        kontrak:[],
        vaksin:[],
      },
      toolbarOptions: ['Search'],
      DataKaryawan: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport,Filter ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    // await Promise.all([this.saya()]) 
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
      this.commands.push({buttonOption: { cssClass: "e-flat Lihat", iconCss: "e-icons mata", content:' '} })
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content: " "} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content: " "} })
    }
    // this.editedItem.TglMasuk = this.date
    // this.editedItem.TglLahir = this.date
    // this.editedItem.Tgl_Phk = this.date
    // this.editedItem.TanggalMulai = this.date
    // this.editedItem.TanggalAkhir = this.date
  },

  created(){
    //get action for this page
    // console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleKaryawan () {
      return this.editedIndex === -1 ? 'Tambah Karyawan Baru' : 'Ubah Data Karyawan'
    },
    formTitleAnak () {
      return this.ChildIndex === -1 ? 'Input Data Anak' : 'Ubah Data Anak'
    },
    formTitleKodeJabatan () {
      return this.editedIndex === -1 ? 'Pilih Kode Jabatan' : 'Pilih Kode Jabatan'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
    StatusFormKodeJabatan () {
      return this.editedIndex === -1 ? 'Pilih' : 'Ubah'
    },
    StatusFormAnak () {
      return this.ChildIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
    Kontrak(){
      // console.log(this.Kontrak)
    },
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
    DataAnak(){
      if (this.DataAnak == null) {
        this.editedItem.Anak = 0
      }else{
        this.editedItem.Anak = 0
        this.editedItem.Anak = this.DataAnak.length
      }
    //  console.log(this.editedItem.Anak)
    },
    FotoKaryawan:{
      handler(){
      if (this.formTitleKaryawan == "Ubah Data Karyawan" && this.DialogTambahKaryawan == true) {
        this.dialogchangefoto = true
        }
      }
    },
    FotoBpjsKes:{
      handler(){
      if (this.formTitleKaryawan == "Ubah Data Karyawan" && this.DialogTambahKaryawan == true) {
        this.dialogchangebpjskes = true
        }
      }
    },
    FotoBpjsTK:{
      handler(){
      if (this.formTitleKaryawan == "Ubah Data Karyawan" && this.DialogTambahKaryawan == true) {
        this.dialogchangebpjstk = true
        }
      }
    },
    FotoKTP:{
      handler(){
      if (this.formTitleKaryawan == "Ubah Data Karyawan" && this.DialogTambahKaryawan == true) {
        this.dialogchangektp = true
        }
      }
    },
    FotoNPWP:{
      handler(){
      if (this.formTitleKaryawan == "Ubah Data Karyawan" && this.DialogTambahKaryawan == true) {
        this.dialogchangenpwp = true
        }
      }
    },
    // DataFotoVaksin:{
    //   handler(){
    //   if (this.formTitleKaryawan == "Ubah Data Karyawan" && this.DialogTambahKaryawan == true && this.editedItem.vaksin.length != 0 && this.editedItem.vaksin[this.pagevaksin-1].Sertifikat != '') {
    //     this.dialogchangesertifikat = true
    //     // console.log(this.fotovaksin)
    //     }
    //   }
    // },

    DialogTambahKaryawan (val) {
      this.pagevaksin = 1
      this.lengthvaksin = 1
      val || this.KeluarDialogKaryawan()
    },
    DialogKodeJabatan (val) {
      val || this.KeluarDialogKodeJabatan()
    },
    DialogAnak (val) {
      val || this.KeluarDialogAnak()
    },
    MenuTglLahir (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    FormatTanggal(x){
      var todayTime = new Date(x);
      var bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      var month = bulan[todayTime .getMonth()];
      var day = todayTime .getDate();
      var year = todayTime .getFullYear();
      return day + " " + month + " " + year;  
    },

    // KeyPressTextFieldMati(e){
    //     e.preventDefault();
    //     e.stopPropagation();
    // },
    // KeyDownTextFieldMati(e){
    //   if( e.which == 8){   
    //   e.preventDefault();  
    //   return false;   
    //   } 
    // },
    refreshdata(){
      this.loading = true
      this.getdata()
      this.Toast('Refresh Data')
    },
    tambahvaksin(){
      if(this.formTitleKaryawan == 'Tambah Karyawan Baru'){
        this.editedItem.vaksin = this.editedItem.vaksin == undefined ? [] :this.editedItem.vaksin
        let VaksinKe = this.lengthvaksin == 1 ? 1 : this.editedItem.vaksin[this.lengthvaksin-1].VaksinKe
        let datavaksin
        let datavaksin2
        if (this.lengthvaksin == 1) {
          // console.log('jalan yg pertama')
          datavaksin = {
          "VaksinKe":VaksinKe,"TanggalVaksin":this.Vaksin.TanggalVaksin,"Keterangan":this.Vaksin.Keterangan,"Sertifikat":this.fotovaksin
        }
        datavaksin2 = {
          "VaksinKe":VaksinKe+1,"TanggalVaksin":"","Keterangan":"","Sertifikat":""
        }
        this.editedItem.vaksin.push(datavaksin,datavaksin2)
        this.lengthvaksin = this.editedItem.vaksin.length
        this.pagevaksin = this.lengthvaksin
        }else{
          // console.log('jalan yg kedua')
          datavaksin2 = {
          "VaksinKe":VaksinKe+1,"TanggalVaksin":"","Keterangan":"","Sertifikat":""
        }
        this.editedItem.vaksin.push(datavaksin2)
        this.lengthvaksin = this.editedItem.vaksin.length
        }
        this.gantiPageVaksin(this.editedItem.vaksin.length)
      }else{
      this.lengthvaksin = this.lengthvaksin+1
      this.pagevaksin = this.lengthvaksin
      let datavaksin = {"VaksinKe":this.lengthvaksin,"TanggalVaksin":"","Keterangan":"","Sertifikat":""}
      this.editedItem.vaksin.push(datavaksin)
      this.editedItem.vaksin[this.lengthvaksin-1].VaksinKe = this.lengthvaksin
      this.gantiPageVaksin(datavaksin.VaksinKe)
      }
      // console.log(this.editedItem.vaksin)
      // console.log('tes',this.VaksinDefault)
    },
    gantiPage(pagekontrakkaryawan){
      this.Kontrak = this.editedItem.kontrak[pagekontrakkaryawan-1]
    },
    gantiPageVaksin(pagevaksin){
      // console.log(this.editedItem)
      this.Vaksin = this.editedItem.vaksin[pagevaksin-1]
      // console.log(this.fotovaksin)
      if(this.editedItem.vaksin[pagevaksin-1].Sertifikat == '' || this.editedItem.vaksin[pagevaksin-1].Sertifikat.charAt(0) == 'b'){
        this.fotovaksin = this.editedItem.vaksin[pagevaksin-1].Sertifikat
        this.fotovaksinTemp = this.editedItem.vaksin[pagevaksin-1].Sertifikat
      }else{
        this.fotovaksin = this.server+this.editedItem.vaksin[pagevaksin-1].Sertifikat
        this.fotovaksinTemp = this.server+this.editedItem.vaksin[pagevaksin-1].Sertifikat
        // this.fotovaksin = "http://"+window.location.hostname+":8000"+this.editedItem.vaksin[pagevaksin-1].Sertifikat
        // this.fotovaksinTemp = "http://"+window.location.hostname+":8000"+this.editedItem.vaksin[pagevaksin-1].Sertifikat
      }
      // this.fotovaksin = this.editedItem.vaksin[pagevaksin-1].KodeKaryawan == undefined ? this.editedItem.vaksin[pagevaksin-1].Sertifikat : "http://"+window.location.hostname+":8000"+this.editedItem.vaksin[pagevaksin-1].Sertifikat
      // this.fotovaksinTemp = this.editedItem.vaksin[pagevaksin-1].KodeKaryawan == undefined ? this.fotovaksinTemp[pagevaksin-1].Sertifikat : "http://"+window.location.hostname+":8000"+this.editedItem.vaksin[pagevaksin-1].Sertifikat
      // console.log(this.fotovaksin)
    },
    checknum(e) {
           this.Child.AnakKe = e.replace(/[^0-9]/g, '');
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,
        //  { headers: {"Authorization" : `Bearer ${this.token}`} }
         ).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        PilihKodeJabatan(){
          this.DialogKodeJabatan = false
        },
        
      // },
    SimpanAnak(){
      if(this.formTitleKaryawan === "Tambah Karyawan Baru" && this.formTitleAnak === "Input Data Anak"){
        if (this.Child.NamaAnak.length > 0 && this.Child.AnakKe.length > 0 ) {
          this.Toast('Data Ditambahkan')
          this.DataAnak.push(this.Child)
          this.DataAnak = [...this.DataAnak]
          this.$nextTick(() => {
            this.Child = Object.assign({}, this.ChildDefault)
          })
        }
    }else if (this.formTitleKaryawan === "Tambah Karyawan Baru" && this.formTitleAnak === "Ubah Data Anak") {
      if (this.Child.NamaAnak.length > 0 && this.Child.AnakKe.length > 0 ) {
        this.Toast('Data Diubah')
        this.DataAnak[this.arr] = this.Child
        this.DataAnak = [...this.DataAnak]
        this.$nextTick(() => {
          this.Child = Object.assign({}, this.ChildDefault)
          this.ChildIndex = -1
        })
      }
      
    }else if (this.formTitleKaryawan === "Ubah Data Karyawan" && this.formTitleAnak === "Input Data Anak") {
      if (this.Child.NamaAnak.length > 0 && this.Child.AnakKe.length > 0 ) {
        this.Toast('Data Ditambahkan')
        this.DataAnak.push(this.Child)
        this.DataAnak = [...this.DataAnak]
        this.$nextTick(() => {
          this.Child = Object.assign({}, this.ChildDefault)
        })
      }
      
      api.post('/tambahAnak?token='+this.token,{
            KodeKaryawan: this.KodeKaryawanTemp,
            NamaAnak: this.Child.NamaAnak,
            AnakKe: this.Child.AnakKe,
            })
            .then((res)=>{
              if (res) {
                this.getdata()
                // console.log(res)
              } else {
                //
              }
            })
    }else if (this.formTitleKaryawan === "Ubah Data Karyawan" && this.formTitleAnak === "Ubah Data Anak") {
      if (this.Child.NamaAnak.length > 0 && this.Child.AnakKe.length > 0 ) {
        this.DataAnak[this.arr] = this.Child
        this.Toast('Data Diubah')
        this.DataAnak = [...this.DataAnak]
        this.$nextTick(() => {
          this.Child = Object.assign({}, this.ChildDefault)
          this.ChildIndex = -1
        })
      }
      
      api.put("/updateAnak/"+this.KodeKaryawanTemp+'/'+this.AnakKeTemp+'/?token='+this.token,{
            NamaAnak: this.Child.NamaAnak,
            AnakKe: this.Child.AnakKe,
					},
          // { headers: {"Authorization" : `Bearer ${this.token}` } }
          ).then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.AlertBerhasilTerubah = true
						}
						
					})
    }
    },
    
    getJabatan(){
      if (this.valid == true) {
        api.get('/jabatan?token='+this.token,
        // { headers: {"Authorization" : `Bearer ${this.token}`} }
        ).then(
				res => {
					// console.log(res);
          this.DataKodeJabatan = res.data
          // this.loading = false
          this.getUserData()
				},
				err => {
					console.log(err);
				}
			)
      }
      
    },

    Simpan(){
      if(this.formTitleKaryawan === "Tambah Karyawan Baru"){
          var anak = JSON.stringify(this.DataAnak);
          var user = JSON.stringify([this.User])
          var vaksin
          // var jabatan
          // if (this.jabatan.length == 0) {
          //   jabatan = []
          // }else{
          //   jabatan = JSON.stringify(this.editedItem.jabatan)
          // }
          if(this.Vaksin.TanggalVaksin == ""){
            vaksin = []
          }else{
            vaksin = this.editedItem.vaksin[this.pagevaksin-1].KodeKaryawan || this.editedItem.vaksin.length > 1 ? JSON.stringify(this.editedItem.vaksin) : JSON.stringify([this.Vaksin])
          }
          
          // console.log('vas',vaksin)
          this.form.append('files',this.FotoKaryawan);
          this.form.append('filesbpjstk',this.FotoBpjsTK);
          this.form.append('filesbpjskes',this.FotoBpjsKes);
          this.form.append('filesktp',this.FotoKTP);
          this.form.append('filesnpwp',this.FotoNPWP);
          for(let i=0; i<this.DataFotoVaksin.length;i++){this.form.append('FilesVaksin[]',this.DataFotoVaksin[i]);}
          this.form.append('Nama', this.editedItem.Nama);
          this.form.append('NRK', this.editedItem.NRK);
          this.form.append('rawNRK', this.TanggalNrk(this.editedItem.TglMasuk));
          this.form.append('KodeJabatan', this.editedItem.KodeJabatan);
          this.form.append('POH', this.editedItem.POH);
          this.form.append('TglMasuk', this.editedItem.TglMasuk);
          this.form.append('TempatLahir', this.editedItem.TempatLahir);
          this.form.append('TglLahir', this.editedItem.TglLahir);
          this.form.append('AlamatKTP', this.editedItem.AlamatKTP);
          this.form.append('AlamatSekarang', this.editedItem.AlamatSekarang);
          this.form.append('NoTlp', this.editedItem.NoTlp);
          this.form.append('NoTlp', this.editedItem.NoTlpEmergency);
          this.form.append('JenisKelamin', this.editedItem.JenisKelamin);
          this.form.append('PendidikanTerakhir', this.editedItem.PendidikanTerakhir);
          this.form.append('Jurusan', this.editedItem.Jurusan);
          this.form.append('Agama', this.editedItem.Agama);
          this.form.append('Suku', this.editedItem.Suku);
          this.form.append('NoRekTabungan', this.editedItem.NoRekTabungan);
          this.form.append('NoKartuBPJSTK', this.editedItem.NoKartuBPJSTK);
          this.form.append('NoKartuBPJSKes', this.editedItem.NoKartuBPJSKes);
          this.form.append('NoKTP', this.editedItem.NoKTP);
          this.form.append('NoNPWP', this.editedItem.NoNPWP);
          this.form.append('GolDarah', this.editedItem.GolDarah);
          this.form.append('StatusPerkawinan', this.editedItem.StatusPerkawinan);
          this.form.append('StatusKerja', this.editedItem.StatusKerja);
          this.form.append('UkuranBaju', this.editedItem.UkuranBaju);
          this.form.append('UkuranSepatu', this.editedItem.UkuranSepatu);
          this.form.append('Cuti', this.editedItem.Cuti);
          this.form.append('PHK', this.editedItem.PHK);
          this.form.append('TglPHK', this.editedItem.TglPHK);
          this.form.append('Keterangan', this.editedItem.Keterangan);
          this.form.append('NamaIstriSuami', this.editedItem.NamaIstriSuami);
          this.form.append('Cabang', this.editedItem.Cabang);
          this.form.append('Lokasi', this.editedItem.Lokasi);
          this.form.append('KategoriAbsen', this.editedItem.KategoriAbsen);
          this.form.append('DiBuatOleh', this.user.Kode);
          this.form.append('DiubahOleh', this.user.Kode);
          this.form.append('Child', anak);  
          this.form.append('NoKontrak', this.Kontrak.NoKontrak);
          this.form.append('TanggalMulai', this.Kontrak.TanggalMulai);
          this.form.append('TanggalAkhir', this.Kontrak.TanggalAkhir);
          // this.form.append('jabatan',jabatan);
          this.form.append('vaksin',vaksin);
          if (this.User.Kode != '') {this.form.append('User', user);}
          this.Alert = true
          // console.log(this.form)
          // console.log('anak',this.DataAnak)
          const config = { headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } };
          api.post("/karyawan?token="+this.token,this.form,config 
            )
            .then((res) => {
              if(res){
                this.Toast('Data Di Simpan')
                // this.getdata()
              }
              // this.$refs.fileupload.value=null
              this.FotoKaryawan = []
              this.DataFotoVaksin = []
              this.form = new FormData
              this.foto = null
            })
            .catch((error) => {
                    console.log(JSON.stringify(error))
                })
      }else if(this.formTitleKaryawan === "Ubah Data Karyawan"){
        // console.log('tes',this.editedItem)
        // console.log('wow',this.Kontrak.length)
        // console.log('te2',this.Vaksin)
        if(this.Vaksin.TanggalVaksin != ""){ this.editedItem.vaksin.push(this.Vaksin) }
        if(this.Kontrak.TanggalMulai != ""){ this.editedItem.kontrak.push(this.Kontrak) }
        // let DataVaksin = this.editedItem.vaksin.length == 0 ? this.Vaksin : this.editedItem.vaksin
        //  console.log('hadeh::',DataVaksin)
        // if(this.Vaksin.length == 0){console.log('wadowh kosong')}
        //  api.put("/karyawan/"+this.KodeKaryawan+'/?token='+this.token,{
            api.put("/karyawan/"+this.editedItem.id+'/?token='+this.token,{
            KodeKaryawan:this.editedItem.KodeKaryawan,
            Nama: this.editedItem.Nama,
            NRK: this.editedItem.NRK,
            KodeJabatan: this.editedItem.KodeJabatan,
            POH: this.editedItem.POH,
            Cabang:this.editedItem.Cabang,
            Lokasi:this.editedItem.Lokasi,
            TglMasuk: this.editedItem.TglMasuk,
            TempatLahir: this.editedItem.TempatLahir,
            TglLahir: this.editedItem.TglLahir,
            AlamatKTP: this.editedItem.AlamatKTP,
            AlamatSekarang: this.editedItem.AlamatSekarang,
            NoTlp: this.editedItem.NoTlp,
            NoTlpEmergency: this.editedItem.NoTlpEmergency,
            JenisKelamin: this.editedItem.JenisKelamin,
            PendidikanTerakhir: this.editedItem.PendidikanTerakhir,
            Jurusan: this.editedItem.Jurusan,
            Agama:this.editedItem.Agama,
            Suku: this.editedItem.Suku,
            NoRekTabungan: this.editedItem.NoRekTabungan,
            NoKartuBPJSTK: this.editedItem.NoKartuBPJSTK,
            NoKartuBPJSKes: this.editedItem.NoKartuBPJSKes,
            NoNPWP: this.editedItem.NoNPWP,
            NoKTP: this.editedItem.NoKTP,
            Cuti:this.editedItem.Cuti,
            GolDarah: this.editedItem.GolDarah,
            StatusPerkawinan: this.editedItem.StatusPerkawinan,
            StatusKerja: this.editedItem.StatusKerja,
            UkuranBaju: this.editedItem.UkuranBaju,
            UkuranSepatu: this.editedItem.UkuranSepatu,
            KategoriAbsen:this.editedItem.KategoriAbsen,
            PHK: this.editedItem.PHK,
            TglPHK: this.editedItem.TglPHK,
            Keterangan: this.editedItem.Keterangan,
            NamaIstriSuami: this.editedItem.NamaIstriSuami,
            kontrak:this.editedItem.kontrak,
            vaksin:this.editedItem.vaksin,
            DiubahOleh: this.user.Kode
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.Toast('Data Di Ubah')
              // this.getdata()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.$refs.fileupload.value=null
      this.FotoKaryawan = []
      this.form = new FormData
      this.foto = null
      this.KeluarDialogKaryawan()   
    },
    commandAnak: function(args) {
      if (args.target.classList.contains("custombutton")){
        // console.log(args.rowData);
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true && this.formTitleKaryawan === "Ubah Data Karyawan") {
          this.Toast('Data Dihapus') 
          const index = this.DataAnak.findIndex(x => x.NamaAnak === args.rowData.NamaAnak);
          this.DataAnak.splice(index,1)
          this.DataAnak = [...this.DataAnak]
          api
            .delete("/hapusAnak/"+this.KodeKaryawanTemp+'/'+args.rowData.AnakKe+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }else if (r == true && this.formTitleKaryawan === "Tambah Karyawan Baru") {
          this.Toast('Data Dihapus')
          const index = this.DataAnak.findIndex(x => x.NamaAnak === args.rowData.NamaAnak);
          this.DataAnak.splice(index,1)
          this.DataAnak = [...this.DataAnak]
        }
      } else if (args.target.classList.contains("Edit")){
        this.AnakKeTemp = args.rowData.AnakKe
        this.ChildIndex = 1
        let data = args
            // console.log(data)
            this.arr = this.DataAnak.findIndex(x => x.NamaAnak === args.rowData.NamaAnak && x.AnakKe === args.rowData.AnakKe);
            this.Child.NamaAnak = data.rowData.NamaAnak
            this.Child.AnakKe = data.rowData.AnakKe
      }
    },

    MasaKerjaDetail(tgl){
      var TanggalMulai = new Date(tgl);
      var diffDate = new Date(new Date() - TanggalMulai);
      return ((diffDate.toISOString().slice(0, 4) - 1970) + " Tahun " +
          diffDate.getMonth() + " Bulan " + (diffDate.getDate()-1) + " Hari");
    },
    CekMasaKerja(){
      var TanggalSekarang = new Date()
      var Hari = String(TanggalSekarang.getDate()). padStart(2, '0')
      var Bulan = String(TanggalSekarang.getMonth() + 1).padStart(2, '0')
      var Tahun = TanggalSekarang.getFullYear();
      const SatuHari = 24 * 60 * 60 * 1000; // jam*menit*detik*milidetik
      const TanggalAwal = new Date(this.datakaryawan.TglMasuk)
      const TanggalAkhir = new Date(Tahun + '-' + Bulan + '-' + Hari)
      const HitungHari = Math.round(Math.abs((TanggalAwal - TanggalAkhir) / SatuHari))
      this.MasaKerja = this.MasaKerjaDetail(this.datakaryawan.TglMasuk) +' / '+ HitungHari
    },

    TanggalNrk(date) {
        var today = new Date(date);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy+mm+dd;
          return today;
        },

    cariKode(Nama){
        if (Nama == null || Nama == '' || Nama.length == 0) {
          this.textAktif = true
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
          })
        }else{
      let ax = '0101/'
      let findnama = ax+Nama.toUpperCase()
      let a = this.DataUser.filter( function(item){return (item.Kode == findnama);} );
        if (a.length > 0) {
        this.alertNama = "Nama User ini sudah di gunakan silahkan coba yg lain"
        this.textAktif = true
        this.User.Kode = ''
        // this.editedItem.Kode = ax+ss+1
        }else{
          this.textAktif = false
          this.alertNama = ''
          this.User.Kode = findnama
          this.User.Nama = Nama
          
        }
      }
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);
      } else if (args.target.classList.contains("Lihat")) {
        console.log('console lihat',args)
        this.DialogLihatDataKaryaran = true
        this.KodeKaryawanTemp =  args.rowData.KodeKaryawan
        const data = args
          this.editedIndex = 1;
          this.loading_show_data_karyawan = true
          this.foto = this.server+data.rowData.Photo
          this.FotoKtpObj = this.server+data.rowData.PhotoKTP
          this.FotoBpjsKesObj = this.server+data.rowData.PhotoBPJSKes
          this.FotoBpjsTKObj = this.server+data.rowData.PhotoBPJSTK
          this.FotoNPWPObj = this.server+data.rowData.PhotoNPWP
          this.fotoTemp = this.server+data.rowData.Photo
          this.fotovaksin = data.rowData.vaksin.length != 0 ? this.server+data.rowData.vaksin[data.rowData.vaksin.length-1].Sertifikat : null
          this.fotovaksinTemp = data.rowData.vaksin.length != 0 ? this.server+data.rowData.vaksin[data.rowData.vaksin.length-1].Sertifikat : null
          api.get('/karyawan/'+data.rowData.id+'?token='+this.token).then(
            res=>{
              console.log(res.data)
              this.loading_show_data_karyawan = false
              this.editedItem = res.data
              this.DataAnak = res.data.anak
              this.KodeKaryawan = res.data.KodeKaryawan
              this.lengthKontrak = res.data.kontrak.length
              this.lengthvaksin = res.data.vaksin.length > 0 ? res.data.vaksin.length : 1
              this.pagekontrakkaryawan = this.editedItem.kontrak.length
              this.pagevaksin = res.data.vaksin.length > 0 ? res.data.vaksin.length : 1
              this.Vaksin = res.data.vaksin.length == 0 ? this.Vaksin : res.data.vaksin[res.data.vaksin.length-1]
              this.editedItem.Anak = this.DataAnak.length
              this.Kontrak = res.data.kontrak.length == 0 ? this.Kontrak : res.data.kontrak[res.data.kontrak.length-1]
              this.MasaKerja = this.MasaKerjaDetail(res.data.TglMasuk)
              if (res.data.jabatan != null) {
              this.Jabatan = res.data.jabatan
              }
              if (res.data.user != null) {
              this.User = res.data.user
              }
              // if (res.data) {
              //   this.dialog = true
              // }
            })
        // console.log(args)
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          api
            .delete("/karyawan/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
              this.Toast('Data DiHapus')
              this.getdata()
              // this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.TampilNama = args.rowData.Nama
        this.KodeKaryawanTemp =  args.rowData.KodeKaryawan
        const data = args
          this.editedIndex = 1;
          this.DialogTambahKaryawan = true
          this.foto = this.server+data.rowData.Photo
          this.FotoKtpObj = this.server+data.rowData.PhotoKTP
          this.FotoBpjsKesObj = this.server+data.rowData.PhotoBPJSKes
          this.FotoBpjsTKObj = this.server+data.rowData.PhotoBPJSTK
          this.FotoNPWPObj = this.server+data.rowData.PhotoNPWP
          this.fotoTemp = this.server+data.rowData.Photo
          // this.foto = "http://"+window.location.hostname+":8000"+data.rowData.Photo
          // this.fotoTemp = "http://"+window.location.hostname+":8000"+data.rowData.Photo
          this.fotovaksin = data.rowData.vaksin.length != 0 ? this.server+data.rowData.vaksin[data.rowData.vaksin.length-1].Sertifikat : null
          this.fotovaksinTemp = data.rowData.vaksin.length != 0 ? this.server+data.rowData.vaksin[data.rowData.vaksin.length-1].Sertifikat : null
          // this.fotovaksin = data.rowData.vaksin.length != 0 ? "http://"+window.location.hostname+":8000"+data.rowData.vaksin[data.rowData.vaksin.length-1].Sertifikat : null
          // this.fotovaksinTemp = data.rowData.vaksin.length != 0 ? "http://"+window.location.hostname+":8000"+data.rowData.vaksin[data.rowData.vaksin.length-1].Sertifikat : null
          api.get('/karyawan/'+data.rowData.id+'?token='+this.token).then(
            res=>{
              // console.log(res.data)
              this.editedItem = res.data
              this.DataAnak = res.data.anak
              this.KodeKaryawan = res.data.KodeKaryawan
              this.lengthKontrak = res.data.kontrak.length
              this.lengthvaksin = res.data.vaksin.length > 0 ? res.data.vaksin.length : 1
              this.pagekontrakkaryawan = this.editedItem.kontrak.length
              this.pagevaksin = res.data.vaksin.length > 0 ? res.data.vaksin.length : 1
              this.Vaksin = res.data.vaksin.length == 0 ? this.Vaksin : res.data.vaksin[res.data.vaksin.length-1]
              this.editedItem.Anak = this.DataAnak.length
              this.Kontrak = res.data.kontrak.length == 0 ? this.Kontrak : res.data.kontrak[res.data.kontrak.length-1]
              this.MasaKerja = this.MasaKerjaDetail(res.data.TglMasuk)
              if (res.data.jabatan != null) {
              this.Jabatan = res.data.jabatan
              }
              if (res.data.user != null) {
              this.User = res.data.user
              }
              if (res.data) {
                this.dialog = true
              }
            })
      }
    },
    gantifotokaryawan(){
    this.dialogchangefoto = false
    this.form.append('files',this.FotoKaryawan);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        api.post("/karyawanFoto/"+this.KodeKaryawan+"?token="+this.token,this.form,config)
          .then((res) => {
            // console.log(res.data.foto)
            if(res){
              //do nothing
            this.Toast('Foto Diubah')
            // this.getdata()
            this.foto = this.server+res.data.foto
            this.fotoTemp = this.server+res.data.foto
            // this.foto = "http://"+window.location.hostname+":8000"+res.data.foto
            // this.fotoTemp = "http://"+window.location.hostname+":8000"+res.data.foto
            }
            this.$refs.fileupload.value=null
            this.form = new FormData
          })
          .catch((error) => {
                  console.log(JSON.stringify(error))
              })
    },
    gantifotobpjstk(){
    this.dialogchangebpjstk = false
    this.form.append('filesbpjstk',this.FotoBpjsTK);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        api.post("/karyawanBpjsTk/"+this.KodeKaryawan+"?token="+this.token,this.form,config)
          .then((res) => {
            // console.log(res.data.foto)
            if(res){
              //do nothing
            this.Toast('Foto Diubah')
            // this.getdata()
            // this.foto = this.server+res.data.foto
            // this.fotoTemp = this.server+res.data.foto
            // this.foto = "http://"+window.location.hostname+":8000"+res.data.foto
            // this.fotoTemp = "http://"+window.location.hostname+":8000"+res.data.foto
            }
            this.$refs.fileupload.value=null
            this.form = new FormData
          })
          .catch((error) => {
                  console.log(JSON.stringify(error))
              })
    },
    gantifotobpjskes(){
    this.dialogchangebpjskes = false
    this.form.append('filesbpjskes',this.FotoBpjsKes);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        api.post("/karyawanBpjsKes/"+this.KodeKaryawan+"?token="+this.token,this.form,config)
          .then((res) => {
            // console.log(res.data.foto)
            if(res){
              //do nothing
            this.Toast('Foto Diubah')
            // this.getdata()
            // this.foto = this.server+res.data.foto
            // this.fotoTemp = this.server+res.data.foto
            // this.foto = "http://"+window.location.hostname+":8000"+res.data.foto
            // this.fotoTemp = "http://"+window.location.hostname+":8000"+res.data.foto
            }
            this.$refs.fileupload.value=null
            this.form = new FormData
          })
          .catch((error) => {
                  console.log(JSON.stringify(error))
              })
    },
    gantifotoKTP(){
    this.dialogchangektp = false
    this.form.append('filesktp',this.FotoKTP);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        api.post("/karyawanKTP/"+this.KodeKaryawan+"?token="+this.token,this.form,config)
          .then((res) => {
            // console.log(res.data.foto)
            if(res){
              //do nothing
            this.Toast('Foto Diubah')
            // this.getdata()
            // this.foto = this.server+res.data.foto
            // this.fotoTemp = this.server+res.data.foto
            // this.foto = "http://"+window.location.hostname+":8000"+res.data.foto
            // this.fotoTemp = "http://"+window.location.hostname+":8000"+res.data.foto
            }
            this.$refs.fileupload.value=null
            this.form = new FormData
          })
          .catch((error) => {
                  console.log(JSON.stringify(error))
              })
    },
    gantifotoNPWP(){
    this.dialogchangenpwp = false
    this.form.append('filesnpwp',this.FotoNPWP);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        api.post("/karyawanNPWP/"+this.KodeKaryawan+"?token="+this.token,this.form,config)
          .then((res) => {
            // console.log(res.data.foto)
            if(res){
              //do nothing
            this.Toast('Foto Diubah')
            // this.getdata()
            // this.foto = this.server+res.data.foto
            // this.fotoTemp = this.server+res.data.foto
            // this.foto = "http://"+window.location.hostname+":8000"+res.data.foto
            // this.fotoTemp = "http://"+window.location.hostname+":8000"+res.data.foto
            }
            this.$refs.fileupload.value=null
            this.form = new FormData
          })
          .catch((error) => {
                  console.log(JSON.stringify(error))
              })
    },
    gantifotovaksin(){
    this.dialogchangesertifikat = false
    for(let i=0; i<this.DataFotoVaksin.length;i++){this.form.append('FilesVaksin[]',this.DataFotoVaksin[i]);}
    this.form.append('VaksinKe',this.Vaksin.VaksinKe)
    this.form.append('KodeKaryawan',this.$user.KodeKaryawan)
    // this.form.append('FilesVaksin[]',this.DataFotoVaksin);
    if(this.formTitleKaryawan == "Ubah Data Karyawan" && this.fotovaksin == '' || this.fotovaksin == null){
      //do nothing
      // console.log(this.DataFotoVaksin)
    }else{
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        api.post("/fotoVaksin/"+this.KodeKaryawan+"?token="+this.token,this.form,config)
          .then((res) => {
            // console.log(res.data.foto)
            if(res){
              //do nothing
              this.Toast('Foto Diubah')
            // this.getdata()
            this.Vaksin = this.editedItem.vaksin[this.pagevaksin-1]
            // this.foto = this.server+res.data.foto
            // this.fotoTemp = this.server+res.data.foto
            // this.fotovaksin = "http://"+window.location.hostname+":8000"+res.data.vaksin[this.pagevaksin].Sertifikat
            // this.fotovaksinTemp = "http://"+window.location.hostname+":8000"+res.data.vaksin[this.pagevaksin].Sertifikat
            this.fotovaksin = this.editedItem.vaksin[this.pagevaksin-1].KodeKaryawan == undefined ? this.editedItem.vaksin[this.pagevaksin-1].Sertifikat : this.editedItem.vaksin[this.pagevaksin-1].Sertifikat
            this.fotovaksinTemp = this.editedItem.vaksin[this.pagevaksin-1].KodeKaryawan == undefined ? this.fotovaksinTemp[this.pagevaksin-1].Sertifikat : this.editedItem.vaksin[this.pagevaksin-1].Sertifikat
            // console.log(this.fotovaksin)
            }
            this.DataFotoVaksin = []
            this.$refs.fileupload.value=null
            this.form = new FormData
          })
          .catch((error) => {
                  console.log(JSON.stringify(error))
              })
      }
    },

    fieldChange(e){
      // console.log(e)
				let selectedFiles=e.target.files;
				if(!selectedFiles.length){
					return false;
				}
				for(let i=0;i<selectedFiles.length;i++){
					this.FotoKaryawan = selectedFiles[0];
				}
          const file = this.FotoKaryawan;
          this.foto = URL.createObjectURL(file);
    },
    fieldChangeBpjsTK(e){
      // console.log(e)
				let selectedFiles=e.target.files;
				if(!selectedFiles.length){
					return false;
				}
				for(let i=0;i<selectedFiles.length;i++){
					this.FotoBpjsTK = selectedFiles[0];
				}
          const file = this.FotoBpjsTK;
          this.FotoBpjsTKObj = URL.createObjectURL(file);
    },
    fieldChangeBpjsKes(e){
      // console.log(e)
				let selectedFiles=e.target.files;
				if(!selectedFiles.length){
					return false;
				}
				for(let i=0;i<selectedFiles.length;i++){
					this.FotoBpjsKes = selectedFiles[0];
				}
          const file = this.FotoBpjsKes;
          this.FotoBpjsKesObj = URL.createObjectURL(file);
    },
    fieldChangeKTP(e){
      // console.log(e)
				let selectedFiles=e.target.files;
				if(!selectedFiles.length){
					return false;
				}
				for(let i=0;i<selectedFiles.length;i++){
					this.FotoKTP = selectedFiles[0];
				}
          const file = this.FotoKTP;
          this.FotoKtpObj = URL.createObjectURL(file);
    },
    fieldChangeNPWP(e){
      // console.log(e)
				let selectedFiles=e.target.files;
				if(!selectedFiles.length){
					return false;
				}
				for(let i=0;i<selectedFiles.length;i++){
					this.FotoNPWP = selectedFiles[0];
				}
          const file = this.FotoNPWP;
          this.FotoNPWPObj = URL.createObjectURL(file);
    },
    fieldChangeVaksin(e){
      // console.log(e)
				let selectedFiles=e.target.files;
				if(!selectedFiles.length){
					return false;
				}
				for(let i=0;i<selectedFiles.length;i++){
					// this.DataFotoVaksin = selectedFiles[0];
          this.DataFotoVaksin.push(selectedFiles[i])
				}
        // console.log('ww',this.DataFotoVaksin)
        // console.log('sss',this.pagevaksin-1)
        let datavaksin
        
        // console.log('cc',this.fotovaksin)
        // console.log('wsw',this.editedItem)
        if(this.editedItem.vaksin && this.editedItem.vaksin.length == 0){
          datavaksin = {
          "VaksinKe":this.pagevaksin,"TanggalVaksin":this.Vaksin.TanggalVaksin,"Keterangan":this.Vaksin.Keterangan,"Sertifikat":""
          }
          this.editedItem.vaksin.push(datavaksin)
        }
        // if (this.editedItem.vaksin == undefined) {
          this.fotovaksin = this.editedItem.vaksin[this.pagevaksin-1].Sertifikat == '' ?
           URL.createObjectURL(this.DataFotoVaksin[this.DataFotoVaksin.length-1]) :
           URL.createObjectURL(this.DataFotoVaksin[this.DataFotoVaksin.length-1]);
        // }else{
        //   //do nothing
        // }
        if(this.editedItem.vaksin != undefined){
          this.editedItem.vaksin[this.pagevaksin-1].Sertifikat = this.fotovaksin
        }else{
          //do notihing
        }
        // this.editedItem.vaksin != undefined ? this.editedItem.vaksin[this.pagevaksin-1].Sertifikat = this.fotovaksin : //do nothing
        // const file = this.DataFotoVaksin;
          // this.fotovaksin = URL.createObjectURL(file);
          // console.log('hehe',DataFotoVaksin)
        if (this.formTitleKaryawan == "Ubah Data Karyawan" && this.DialogTambahKaryawan == true && this.editedItem.vaksin.length != 0 && this.editedItem.vaksin[this.pagevaksin-1].Sertifikat != '') {
        this.dialogchangesertifikat = true
        // console.log(this.fotovaksin)
        }
          
          
    },
    PdfExport(){
      let data = document.getElementById('karyawanview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportKaryawan.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridkaryawan.columns[0].visible = false;
      // this.$refs.gridkaryawan.columns[1].visible = true;
      this.$refs.gridkaryawan.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridkaryawan.excelExport();
      // console.log(this.$refs.gridkaryawan)
      // let data = document.getElementById('karyawanview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    TabFotoKaryawan () {
      this.ShowTabFotoKaryawan = true
      this.ShowTabBPJS_TK = false
      this.ShowTabBPJS_KES = false
      this.ShowTabKTP = false
      this.ShowTabNPWP = false
    },

    TabBPJS_TK () {
      this.ShowTabFotoKaryawan = false
      this.ShowTabBPJS_TK = true
      this.ShowTabBPJS_KES = false
      this.ShowTabKTP = false
      this.ShowTabNPWP = false
    },

    TabBPJS_KES () {
      this.ShowTabFotoKaryawan = false
      this.ShowTabBPJS_TK = false
      this.ShowTabBPJS_KES = true
      this.ShowTabKTP = false
      this.ShowTabNPWP = false
    },

    TabKTP () {
      this.ShowTabFotoKaryawan = false
      this.ShowTabBPJS_TK = false
      this.ShowTabBPJS_KES = false
      this.ShowTabKTP = true
      this.ShowTabNPWP = false
    },

    TabNPWP () {
      this.ShowTabFotoKaryawan = false
      this.ShowTabBPJS_TK = false
      this.ShowTabBPJS_KES = false
      this.ShowTabKTP = false
      this.ShowTabNPWP = true
    },

    ShowAll () {
      this.showData = true
      this.showFoto = true
      this.showKontrak = true
      this.showJabatan = true
      this.showUser = true
      this.showVaksin = true
    },

    CloseAll () {
      this.showData = false
      this.showFoto = false
      this.showKontrak = false
      this.showJabatan = false
      this.showUser = false
      this.showVaksin = false
    },

    KeluarDialogKaryawan () {
      this.DialogTambahKaryawan = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.Kontrak = Object.assign({}, this.KontrakDefault)
        this.Vaksin = Object.assign({}, this.VaksinDefault)
        this.User = Object.assign({}, this.UserDefault)
        this.lengthvaksin = 0
        this.pagevaksin = 0
        this.editedIndex = -1
        this.foto = null
        this.FotoBpjsTKObj = null
        this.FotoBpjsKesObj = null
        this.FotoKtpObj = null
        this.FotoNPWPObj = null
        this.fotovaksin = null
        this.DataFotoVaksin = []
        this.DataAnak = []
        this.editedItem.kontrak
        this.editedItem.vaksin = []
        this.editedItem.Tgl_Masuk = this.date
        this.editedItem.Tgl_Lahir = this.date
        this.editedItem.Tgl_Phk = this.date
        this.editedItem.TanggalMulai = this.date
        this.editedItem.TanggalAkhir = this.date
        this.showData = false
        this.showFoto = false
        this.showKontrak = false
        this.showJabatan = false
        this.showUser = false
        this.showVaksin = false
        this.TampilNama = ''
        // this.clearFileInput("upload-file");
        // this.clearFileInput("upload-file-vaksin");
      })
    },

    KeluarDialogKodeJabatan () {
      this.DialogKodeJabatan = false
    },

    KeluarDialogAnak () {
      this.DialogAnak = false
      const a = this.editedItem
      this.$nextTick(() => {   
        this.Child = Object.assign({}, this.ChildDefault)
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem = a
        this.editedItem.Anak = this.DataAnak.length
        this.ChildIndex = -1
      })
    },

    // beforeExport: function(args) {
      
    // },
    rowSelectedKodeJabatan:function(args){
      this.editedItem.KodeJabatan = args.data.KodeJabatan
      this.Jabatan.NamaJabatan = args.data.NamaJabatan
      this.Jabatan.KodePerusahaan = args.data.KodePerusahaan
      this.Jabatan.Departemen = args.data.Departemen
      this.Jabatan.Divisi = args.data.Divisi
      this.Jabatan.Atasan = args.data.Atasan
    },
    rowDblClickKodeJabatan: function(args){
      this.editedItem.KodeJabatan = args.rowData.KodeJabatan
      this.Jabatan.NamaJabatan = args.rowData.NamaJabatan
      this.Jabatan.KodePerusahaan = args.rowData.KodePerusahaan
      this.Jabatan.Departemen = args.rowData.Departemen
      this.Jabatan.Divisi = args.rowData.Divisi
      this.Jabatan.Atasan = args.rowData.Atasan
      this.DialogKodeJabatan = false
    },
    getUserData(){
      api.get('/user?token='+this.token,
      // { headers: {"Authorization" : `Bearer ${this.token}`} }
      ).then(
				res => {
					// console.log('datauser',res);
          this.getdataCabang()
          this.DataUser = res.data
          let a = []
          res.data.forEach(element => {
            element.menu = element.UserMenu
            a.push(element.menu)
          });
          this.UserMenuItems = [...new Set(a)]
				},
				err => {
					console.log(err);
				}
			)
    },
    getall(){
      api.get('/karyawan?token='+this.token).then(res=>{
          this.DataKaryawan = res.data
          this.loading = false
          this.getJabatan()
      })
    },
    
     getdata(){
      if (this.valid == true) {
       api.get('/karyawanfirst?token='+this.token,
        // { headers: {"Authorization" : `Bearer ${this.token}`} }
        ).then(
				res => {
          this.getall()
					// console.log(res);
          // let karyawan = []
          // for (let index = 0; index < res.data.length; index++) {
          //   const s = res.data[index]
          //   if (res.data[index].kontrak.length != 0) {
          //   s.kontrak = res.data[index].kontrak[res.data[index].kontrak.length-1]
          //   }
          //   karyawan.push(s)
          // }
          // console.log('awok',karyawan)
          this.DataKaryawan = res.data
          // this.DataKaryawan.length > 0 ? this.loading = false : this.loading = true
          // this.loading = false
				},
				err => {
					console.log(err);
				}
			)
      }
    },

    getdataCabang(){
      if (this.valid == true) {
        api.get('/cabang?token='+this.token,
        ).then(
				res => {
          this.getdataKategoriAbsen()
          this.DataCabang = res.data
          console.log('datacabang',res.data)
				},
				err => {
					console.log(err);
				}
			)
      }
    },
    getdataKategoriAbsen(){
      if (this.valid == true) {
        api.get('/kategoriabsen?token='+this.token,
        ).then(
				res => {
          this.DataKategoriAbsen = res.data
          this.loading = false
				},
				err => {
					console.log(err);
				}
			)
      }
    },
    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true})
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
    // clearFileInput(id) 
    //   { 
    //       var oldInput = document.getElementById(id); 
    //       console.log(oldInput,'oks')
    //       var newInput = document.createElement("input"); 
    //       newInput.type = "file"; 
    //       newInput.accept="image/png, image/jpeg"
    //       newInput.id = oldInput.id; 
    //       newInput.name = oldInput.name; 
    //       newInput.className = oldInput.className; 
    //       newInput.style.cssText = oldInput.style.cssText; 
    //       newInput.onchange = function(){

    //       }
    //       oldInput.parentNode.replaceChild(newInput, oldInput); 
          
    //   }


  },
}
</script>

<style scoped>
.mata{ 
  content:'\e345'; 
}

.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}

/* .flex-container { 
  display: flex; 
  height: 480px;         
} */
</style>